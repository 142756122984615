import { makeAutoObservable } from "mobx";

export class ThemeStore {
  theme: string;
  changed: number;

  constructor() {
    makeAutoObservable(this);
    const theme = localStorage.getItem("theme");
    if(theme)
    {
      this.theme = theme;
    }
  }

  setTheme(theme) {
    this.theme = theme;
    localStorage.setItem("theme", this.theme);
    this.changed++;
  }
}

export default new ThemeStore();
