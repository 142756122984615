import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthService from "../../services/auth/AuthService";

const EmailConfirmView = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location.search);
    const userId = urlParams.get('id');
    const emailToken = urlParams.get('token');

    const confirmEmail = async () => {
        var result = await AuthService.confirmEmail(userId, encodeURIComponent(emailToken));
        if (result) {
          navigate("/");
        }
      };

    useEffect(() => {
        document.body.style.backgroundImage = "url('assets/media/auth/bg12.jpg')";
        if (window.KTPasswordMeter) {
          window.KTPasswordMeter.createInstances();
        }
        return () => {
          document.body.style.backgroundImage = "";
        };
      }, []);

    return (
        <div className="d-flex flex-center w-100 h-100">
              <div className="card rounded-3 w-md-550px">
              <div className="card-body p-10 p-lg-20 d-flex flex-column flex-center">
                <img src="https://i.imgur.com/5msK3Qh.png" className="w-100px my-6"/>
                <p className="text-gray-500 fw-semibold fs-1">{t("ConfirmYourEmail")}!</p>
                <button onClick={confirmEmail}  className="btn btn-primary w-250px my-5">
                            {t("ConfirmEmail")}
                            </button>
              </div>
              </div>
        </div>
    )
}

export default EmailConfirmView;