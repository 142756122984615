export const routes = {
  "/": {
    key: "Dashboard",
    name: "Dashboard",
    roles: ["Admin", "User", "PremiumUser"],
  },
  "/targeting-settings": {
    key: "TargetSettings",
    name: "Target Settings",
    roles: ["PremiumUser"],
  },
  "/users": {
    key: "Users",
    name: "Users",
    description: "Users list",
    roles: ["Admin"],
  },
  "/user-dashboard": {
    key: "UserDashboard",
    name: "User Dashboard",
    roles: ["Admin"],
  },
  "/my-profile": {
    key: "MyProfile",
    name: "My Profile",
    roles: ["Admin", "User", "PremiumUser"],
  },
};
