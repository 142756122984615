import http from "../common/api";
import AuthService from "./auth/AuthService";

class UsersService {
  getByParameters = (params) => {
    return http
      .get(
        `User/non-admin-users?pageIndex=${params.page ? params.page : 1}&pageSize=${params.pageSize ? params.pageSize : 10}${params.searchTerm ? "&searchTerm=" + params.searchTerm : ""}${
          params.isActive !== null && params.isActive !== undefined ? "&isActive=" + params.isActive : ""
        }`
      )
      .then((response) => {
        return response.data;
      });
  };

  assignRole = (userId, roleName) => {
    return http.post("Admin/assign-role", { userId, roleName }).then((response) => {
      return response.data;
    });
  };

  removeRole = (userId, roleName) => {
    return http.post("Admin/remove-role", { userId, roleName }).then((response) => {
      return response.data;
    });
  };

  deleteUser = (userId) => {
    return http.post(`/Admin/delete-user/${userId}`).then((response) => {
      return response.data;
    });
  };

  editUser = (user) => {
    return http.put("User/" + user.id, user).then((response) => {
      return response.data;
    });
  };

  scrapeData = (user) => {
    return http.get("User/scrape-data/" + user.id).then((response) => {
      return response.data;
    });
  };

  deleteCurrentUser = () => {
    var currentUser = AuthService.getCurrentUser();
    return http
      .delete(`User`, {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      })
      .then((response) => {
        return response.data;
      });
  };

  getUserDashboardData = (id, currentWeek = null, currentMonth = null, from = null, to = null) => {
    var currentUser = AuthService.getCurrentUser();
    return http
      .get(
        `FollowerCount/filter-follower-count/${id}?${currentWeek != null ? "&currentWeek=" + currentWeek : ""}${currentMonth != null ? "&currentMonth=" + currentMonth : ""}${
          from != null ? "&from=" + from : ""
        }${to != null ? "&to=" + to : ""}`,
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        return response.data;
      });
  };

  getUserInfoById = (id) => {
    return http.get(`User/user-info/${id}`).then((response) => {
      return response.data;
    });
  };

  changePassword = (data) => {
    var currentUser = AuthService.getCurrentUser();
    return http
      .post(
        "User/change-password",
        { userId: currentUser.id, ...data },
        {
          headers: { Authorization: `Bearer ${currentUser.accessToken}` },
        }
      )
      .then((response) => {
        return { isSuccessfullyChanged: response.data };
      })
      .catch((err) => {
        return { isSuccessfullyChanged: false, errorMessage: err.response.data };
      });
  };
}

export default new UsersService();
