import { Navigate } from "react-router-dom";
import { routes } from "../../navigation/RouteDescriptions";

function ProtectedRoute({ children }) {
  let auth = localStorage.getItem("user");
  auth = auth ? JSON.parse(auth) : null;

  if (!auth || !auth.accessToken) {
    return <Navigate to="/sign-in" />;
  }

  if (Array.isArray(auth.role) && auth.role.includes("Admin")) {
    const indexOfUser = auth.role.indexOf("User");
    if (indexOfUser !== -1) {
      auth.role.splice(indexOfUser, 1);
    }
    const indexOfPremiumUser = auth.role.indexOf("PremiumUser");
    if (indexOfPremiumUser !== -1) {
      auth.role.splice(indexOfPremiumUser, 1);
    }
  }

  const routeDescriptions = routes[window.location.pathname];
  if (routeDescriptions && routeDescriptions.roles) {
    if ((Array.isArray(auth.role) && !auth.role.some((r) => routeDescriptions.roles.includes(r))) || (!Array.isArray(auth.role) && !routeDescriptions.roles.includes(auth.role))) {
      return <Navigate to="/sign-in" />;
    }
  }

  if (!auth || !auth.accessToken) {
    return <Navigate to="/sign-in" />;
  }
  return children;
}

export default ProtectedRoute;
