import React, { useEffect, useState } from "react";
import UsersService from "../../services/UsersService";
import moment from "moment";
import ApexCharts from "apexcharts";
import AuthService from "../../services/auth/AuthService";
import { InstagramEmbed } from "react-social-media-embed";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const HomeView = observer((props) => {
  const [initialData, setInitialData] = useState(null);
  const [initializedFollowersGraph, setInitializedFollowersGraph] = useState(false);
  const [followersGraphPeriod, setFollowersGraphPeriod] = useState(1);
  const [followersGraph, setFollowersGraph] = useState();

  const [initializedFollowingsGraph, setInitializedFollowingsGraph] = useState(false);
  const [followingsGraphPeriod, setFollowingsGraphPeriod] = useState(1);
  const [followingsGraph, setFollowingsGraph] = useState();

  const [initializedAverageLikesGraph, setInitializedAverageLikesGraph] = useState(false);
  const [averageLikesGraphPeriod, setAverageLikesGraphPeriod] = useState(1);
  const [averageLikesGraph, setAverageLikesGraph] = useState();

  const [initializedAverageCommentsGraph, setInitializedAverageCommentsGraph] = useState(false);
  const [averageCommentsGraphPeriod, setAverageCommentsGraphPeriod] = useState(1);
  const [averageCommentsGraph, setAverageCommentsGraph] = useState();

  const [initializedEngagementRateGraph, setInitializedEngagementRateGraph] = useState(false);
  const [engagementRateGraphPeriod, setEngagmentRateGraphPeriod] = useState(1);
  const [engagementRateGraph, setEngagementRateGraph] = useState();

  const [topPosts, setTopPosts] = useState(null);

  const [hashtags, setHashtags] = useState([]);
  const [loggedUserId, setLoggedUserId] = useState([]);

  const { themeStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (initialData != null) {
      initFollowersGraph();
      initFollowingsGraph();
      initAverageLikesGraph();
      initAverageCommentsGraph();
      initEngagementRateGraph();

      var elements = document.getElementsByClassName("apexcharts-menu-item");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.color = "black";
      }
    }
  }, [themeStore.theme]);

  useEffect(() => {
    const currentUser = AuthService.getCurrentUser();
    if ((Array.isArray(currentUser.role) && currentUser.role.includes("Admin")) || (!Array.isArray(currentUser.role) && currentUser.role === "Admin")) {
      navigate("/users");
    }
    setLoggedUserId(currentUser.id);
    const fetchData = async () => {
      var data = await UsersService.getUserDashboardData(currentUser.id, true);
      setInitialData(data);

      if (data.instagramPosts && data.instagramPosts.length > 0) {
        setTopPosts(data.instagramPosts.slice(0, 9));
      }
      if (data && data.followersCount && data.followersCount.length > 0) {
        var reversedData = data.followersCount.slice().reverse();
        var index = reversedData.findIndex((d) => d.hashtags !== null);
        if (index !== -1) {
          setHashtags(reversedData[index].hashtags.split(","));
        }
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (initialData != null) {
      if (!initializedFollowersGraph) {
        setInitializedFollowersGraph(true);
        initFollowersGraph();
      }

      if (!initializedFollowingsGraph) {
        setInitializedFollowingsGraph(true);
        initFollowingsGraph();
      }

      if (!initializedAverageLikesGraph) {
        setInitializedAverageLikesGraph(true);
        initAverageLikesGraph();
      }

      if (!initializedAverageCommentsGraph) {
        setInitializedAverageCommentsGraph(true);
        initAverageCommentsGraph();
      }

      if (!initializedEngagementRateGraph) {
        setInitializedEngagementRateGraph(true);
        initEngagementRateGraph();
      }

      var elements = document.getElementsByClassName("apexcharts-menu-item");
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.color = "black";
      }
    }
  }, [initialData, initializedFollowersGraph, initializedFollowingsGraph, initializedAverageLikesGraph, initializedAverageCommentsGraph]);

  const initFollowersGraph = async () => {
    var element = document.getElementById("followersGraph");

    if (!element) {
      return;
    }

    element.innerHTML = "";

    var options = {
      series: [
        {
          name: "Followers",
          data: initialData.followersCount.map((d) => d.followers),
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        theme: themeStore.theme,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
      // },
      yaxis: {
        labels: {
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
        },
      },
      xaxis: {
        categories: initialData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return followersGraphPeriod === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    setFollowersGraph(chart);
  };

  const initFollowingsGraph = async () => {
    var element = document.getElementById("followingsGraph");

    if (!element) {
      return;
    }

    element.innerHTML = "";

    var options = {
      series: [
        {
          name: "Followings",
          data: initialData.followersCount.map((d) => d.followings),
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        theme: themeStore.theme,
      },
      stroke: {
        curve: "smooth",
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
      // },
      yaxis: {
        labels: {
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
        },
      },
      xaxis: {
        categories: initialData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return followingsGraphPeriod === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    setFollowingsGraph(chart);
  };

  const initAverageLikesGraph = async () => {
    var element = document.getElementById("averageLikesGraph");

    if (!element) {
      return;
    }

    element.innerHTML = "";

    var options = {
      series: [
        {
          name: "Average Likes",
          data: initialData.followersCount.map((d) => d.averageLikes),
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        theme: themeStore.theme,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
      // },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
        },
      },
      xaxis: {
        categories: initialData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return averageLikesGraphPeriod === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    setAverageLikesGraph(chart);
  };

  const initAverageCommentsGraph = async () => {
    var element = document.getElementById("averageCommentsGraph");

    if (!element) {
      return;
    }

    element.innerHTML = "";

    var options = {
      series: [
        {
          name: "Average Comments",
          data: initialData.followersCount.map((d) => d.averageComments),
        },
      ],
      chart: {
        height: 350,
        type: "line",
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        theme: themeStore.theme,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      // grid: {
      //   row: {
      //     colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      //     opacity: 0.5,
      //   },
      // },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
        },
      },
      xaxis: {
        categories: initialData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return averageCommentsGraphPeriod === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    setAverageCommentsGraph(chart);
  };

  const initEngagementRateGraph = async () => {
    var element = document.getElementById("engagementRateGraph");

    if (!element) {
      return;
    }

    element.innerHTML = "";

    var options = {
      series: [
        {
          name: "Engagement Rate",
          data: initialData.followersCount.map((d) => d.engagementRate),
        },
      ],
      chart: {
        type: "area",
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      tooltip: {
        theme: themeStore.theme,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
        },
      },
      xaxis: {
        categories: initialData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return engagementRateGraphPeriod === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: initialData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
      legend: {
        horizontalAlign: "left",
      },
    };

    var chart = new ApexCharts(element, options);
    chart.render();
    setEngagementRateGraph(chart);
  };

  const updateFollowersGraphPeriod = async (period) => {
    setFollowersGraphPeriod(period);
    var newData = await UsersService.getUserDashboardData(loggedUserId, period === 1 ? true : null, period === 2 ? true : null);

    followersGraph.updateOptions({
      series: [
        {
          name: "Followers",
          data: newData.followersCount.map((d) => d.followers),
        },
      ],
      xaxis: {
        categories: newData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return period === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: newData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    });
  };

  const updateFollowingsGraphPeriod = async (period) => {
    setFollowingsGraphPeriod(period);
    var newData = await UsersService.getUserDashboardData(loggedUserId, period === 1 ? true : null, period === 2 ? true : null);

    followingsGraph.updateOptions({
      series: [
        {
          name: "Followings",
          data: newData.followersCount.map((d) => d.followings),
        },
      ],
      xaxis: {
        categories: newData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return period === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: newData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    });
  };

  const updateAverageLikesGraphPeriod = async (period) => {
    setAverageLikesGraphPeriod(period);
    var newData = await UsersService.getUserDashboardData(loggedUserId, period === 1 ? true : null, period === 2 ? true : null);

    averageLikesGraph.updateOptions({
      series: [
        {
          name: "Average Likes",
          data: newData.followersCount.map((d) => d.averageLikes),
        },
      ],
      xaxis: {
        categories: newData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return period === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: newData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    });
  };

  const updateAverageCommentsGraphPeriod = async (period) => {
    setAverageCommentsGraphPeriod(period);
    var newData = await UsersService.getUserDashboardData(loggedUserId, period === 1 ? true : null, period === 2 ? true : null);

    averageCommentsGraph.updateOptions({
      series: [
        {
          name: "Average Comments",
          data: newData.followersCount.map((d) => d.averageComments),
        },
      ],
      xaxis: {
        categories: newData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return period === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: newData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    });
  };

  const updateEngagmentRateGraphPeriod = async (period) => {
    setEngagmentRateGraphPeriod(period);
    var newData = await UsersService.getUserDashboardData(loggedUserId, period === 1 ? true : null, period === 2 ? true : null);

    engagementRateGraph.updateOptions({
      series: [
        {
          name: "Engagement Rate",
          data: newData.followersCount.map((d) => d.engagementRate),
        },
      ],
      yaxis: {
        labels: {
          formatter: function (value) {
            return value.toFixed(2);
          },
        },
      },
      xaxis: {
        categories: newData.followersCount.map((d) => d.dateCreated),
        labels: {
          formatter: function (value) {
            return period === 1 ? moment(value).format("DD MMM") : moment(value).format("YYYY-MM-DD");
          },
          style: {
            colors: newData.followersCount.map((d) => (themeStore.theme === "dark" ? "white" : "black")),
          },
          hideOverlappingLabels: true,
        },
      },
    });
  };

  return (
    <>
      {" "}
      <div class="col-md-7">
        <div class="box shadow mt-4">
          <div id="line-adwords" class=""></div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-people fs-3"></i> {t("Followers")}
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
            <div className="card-toolbar">
              <button className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${followersGraphPeriod === 1 ? "active" : ""}`} onClick={() => updateFollowersGraphPeriod(1)}>
                {t("Week")}
              </button>
              <button className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${followersGraphPeriod === 2 ? "active" : ""}`} onClick={() => updateFollowersGraphPeriod(2)}>
                {t("Month")}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div id="followersGraph" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-person-plus fs-3"></i> {t("Followings")}
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
            <div className="card-toolbar">
              <button className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${followingsGraphPeriod === 1 ? "active" : ""}`} onClick={() => updateFollowingsGraphPeriod(1)}>
                {t("Week")}
              </button>
              <button
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${followingsGraphPeriod === 2 ? "active" : ""}`}
                href="#!"
                onClick={() => updateFollowingsGraphPeriod(2)}
              >
                {t("Month")}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div id="followingsGraph" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-heart fs-3"></i> {t("AverageLikes")}
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
            <div className="card-toolbar">
              <button className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${averageLikesGraphPeriod === 1 ? "active" : ""}`} onClick={() => updateAverageLikesGraphPeriod(1)}>
                {t("Week")}
              </button>
              <button
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${averageLikesGraphPeriod === 2 ? "active" : ""}`}
                onClick={() => updateAverageLikesGraphPeriod(2)}
              >
                {t("Month")}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div id="averageLikesGraph" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-chat-square-text fs-3"></i> {t("AverageComments")}
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
            <div className="card-toolbar">
              <button
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${averageCommentsGraphPeriod === 1 ? "active" : ""}`}
                onClick={() => updateAverageCommentsGraphPeriod(1)}
              >
                {t("Week")}
              </button>
              <button
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${averageCommentsGraphPeriod === 2 ? "active" : ""}`}
                onClick={() => updateAverageCommentsGraphPeriod(2)}
              >
                {t("Month")}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div id="averageCommentsGraph" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-activity fs-3"></i> {t("EngagementRate")}te
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
            <div className="card-toolbar">
              <button className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 ${engagementRateGraphPeriod === 1 ? "active" : ""}`} onClick={() => updateEngagmentRateGraphPeriod(1)}>
                {t("Week")}
              </button>
              <button
                className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${engagementRateGraphPeriod === 2 ? "active" : ""}`}
                onClick={() => updateEngagmentRateGraphPeriod(2)}
              >
                {t("Month")}
              </button>
            </div>
          </div>
          <div className="card-body">
            <div id="engagementRateGraph" style={{ height: "350px" }}></div>
          </div>
        </div>
      </div>
      <div className="col-xl-6">
        <div className="card card-xl-stretch mb-xl-8">
          <div className="card-header border-0 pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold fs-3 mb-1">
                <i className="bi bi-hash fs-3"></i> {t("Hashtags")}
              </span>
              <span className="text-muted fw-semibold fs-7"></span>
            </h3>
          </div>
          <div className="card-body">
            <div className="hover-scroll-overlay-y pe-6 me-n6" style={{ height: "365px" }}>
              {hashtags &&
                hashtags.map((hashtag, i) => (
                  <div className="d-flex align-items-center mb-5" key={i}>
                    <div className="flex-grow-1">
                      <a href="#!" className="text-gray-800 text-hover-primary fw-bold fs-5">
                        {hashtag}
                      </a>
                    </div>
                  </div>
                ))}
              {hashtags && hashtags.length === 0 && (
                <div className="d-flex align-items-center mb-5">
                  <div className="flex-grow-1">
                    <span className="text-muted text-hover-primary fw-bold fs-5 text-center p-3">{t("NoHashtagsFound")}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-12">
        <div className="card card-xl-stretch mb-5 mb-xl-8">
          <div className="card-header align-items-center border-0 mt-4">
            <h3 className="card-title align-items-start flex-column">
              <span className="fw-bold text-dark">
                <i className="bi bi-arrow-up fs-2"></i> {t("TopPosts")}
              </span>
            </h3>
          </div>
          <div className="card-body pt-0 row">
            {topPosts &&
              topPosts.map((ip, i) => (
                <div className="card card-xl-stretch card-py col-auto border m-3" key={i}>
                  <ul className="nav py-3 justify-content-center">
                    <li className="nav-item">
                      <span className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold fs-5 px-4 me-1" style={{ cursor: "default" }}>
                        <i className="bi bi-heart-fill fs-1 me-1"></i> {ip.likesCount}
                      </span>
                    </li>
                    <li className="nav-item">
                      <span className="nav-link btn btn-sm btn-color-gray-600 btn-active-color-primary fw-bold fs-5 px-4 me-1" style={{ cursor: "default" }}>
                        <i className="bi bi-chat-square-fill fs-1 me-1"></i>
                        {ip.commentsCount}
                      </span>
                    </li>
                  </ul>
                  <div className="separator separator-solid mb-5"></div>
                  <InstagramEmbed url={`https://${ip.postUrl}`} captioned />
                </div>
              ))}

            {!topPosts && <span className="text-muted text-hover-primary fw-bold fs-5 text-center p-3">{t("NoPostsFetchedAtTheMoment")}</span>}
          </div>
        </div>
      </div>
    </>
  );
});

export default HomeView;
