import axios from "axios";

axios.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = `Bearer test`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios.create({
  //  baseURL: "https://api-makaly-staging.housepilot.de/api/",
  baseURL: "https://api.makaly.com/api/",
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: false,
});
