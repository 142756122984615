import React, { useEffect } from "react";
import AuthService from "../../services/auth/AuthService";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UsersService from "../../services/UsersService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

const MyProfileView = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  const {
    register: registerChangePassword,
    handleSubmit: handleSubmitChangePassword,
    formState: { errors: errorsChangePassword, isValid: isValidChangePassword },
    setError: setErrorChangePassword,
    reset: resetChangePassword,
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const fetchUserData = async () => {
      var currentUser = AuthService.getCurrentUser();
      if (currentUser) {
        var userData = await UsersService.getUserInfoById(currentUser.id);
        if (userData) {
          setValue("id", userData.id);
          setValue("firstName", userData.firstName);
          setValue("lastName", userData.lastName);
          setValue("email", userData.email);
          setValue("instagramUsername", userData.instagramUsername);
          setValue("instagramPassword", userData.instagramPassword);
          setValue("paymentMethod", userData.paymentMethod);
          setValue("subscriptionPlan", userData.subscriptionPlan);
          setValue("isPremium", userData.isPremium);
          setValue("dateCreated", userData.dateCreated);
        }
      }
    };
    fetchUserData();
    if (window.KTPasswordMeter) {
      window.KTPasswordMeter.createInstances();
    }
  }, []);

  const onDelete = async () => {
    window.Swal.fire({
      html: t("AreYouSureYouWantToDeleteYourAccount"),
      icon: "question",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        var isDeleted = await UsersService.deleteCurrentUser();
        if (isDeleted) {
          window.Swal.fire({
            html: t("YourAccountHasBeenSuccessfullyDeleted"),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          }).then(async () => {
            await AuthService.logout();
            navigate("/sign-in");
          });
        }
      }
    });
  };

  const onSubmit = (formData) => {
    const editUser = async () => {
      var saveButton = document.getElementById("saveButton");
      saveButton.setAttribute("disabled", "disabled");
      saveButton.setAttribute("data-kt-indicator", "on");
      await UsersService.editUser(formData);
      saveButton.removeAttribute("data-kt-indicator");
      saveButton.removeAttribute("disabled");
      window.Swal.fire({
        html: t("YourProfileSuccessfullyUpdated"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      });
    };
    editUser();
  };

  const onChangePasswordSubmit = async (data) => {
    var changePasswordButton = document.getElementById("changePasswordButton");
    changePasswordButton.setAttribute("disabled", "disabled");
    changePasswordButton.setAttribute("data-kt-indicator", "on");
    const response = await UsersService.changePassword(data);
    changePasswordButton.removeAttribute("data-kt-indicator");
    changePasswordButton.removeAttribute("disabled");
    if (response) {
      if (response.isSuccessfullyChanged) {
        window.$("#modal_change_password").modal("hide");
        window.Swal.fire({
          html: t("YourPasswordSuccessfullyChanged"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger",
          },
        });
        resetChangePassword();
      } else {
        if (response.errorMessage === "Password mismatch") {
          setErrorChangePassword("currentPassword", { message: t("PasswordYouEnteredIsIncorrect") });
        } else if (response.errorMessage === "Passwords do not match") {
          setErrorChangePassword("confirmPassword", { message: t("NewPasswordAndConfirmNewPasswordDontMatch") });
        }
      }
    }
  };

  return (
    <>
      <ToastContainer />
      <div id="kt_account_settings_profile_details" className="collapse show">
        <form onSubmit={handleSubmit(onSubmit)} className="form" noValidate="novalidate" action="#">
          <div className="card-body">
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">{t("FullName")}</label>
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="fname"
                      className={`form-control form-control-lg  mb-3 mb-lg-0 ${errors.firstName ? "border-danger" : ""}`}
                      {...register("firstName", {
                        required: t("FirstNameIsRequired"),
                      })}
                      placeholder={t("FirstName")}
                    />
                    {errors.firstName && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="firstName" data-validator="notEmpty">
                          {errors.firstName.message}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-6 fv-row">
                    <input
                      type="text"
                      name="lname"
                      className={`form-control form-control-lg  ${errors.lastName ? "border-danger" : ""}`}
                      {...register("lastName", {
                        required: t("LastNameIsRequired"),
                      })}
                      placeholder={t("LastName")}
                    />
                    {errors.lastName && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="lastName" data-validator="notEmpty">
                          {errors.lastName.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">{t("Email")}</label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="email"
                  className={`form-control form-control-lg ${errors.email ? "border-danger" : ""}`}
                  {...register("email", {
                    required: t("EmailIsRequired"),
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: t("EmailInvalidFormat"),
                    },
                  })}
                  placeholder={t("Email")}
                />
                {errors.email && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="email" data-validator="notEmpty">
                      {errors.email.message}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">{t("InstagramUsername")}</label>
              <div className="col-lg-8 fv-row">
                <input
                  type="text"
                  name="address"
                  className={`form-control form-control-lg ${errors.instagramUsername ? "border-danger" : ""}`}
                  {...register("instagramUsername", {
                    required: t("InstagramUsernameIsRequired"),
                  })}
                  placeholder={t("InstagramUsername")}
                />
                {errors.instagramUsername && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="instagramUsername" data-validator="notEmpty">
                      {errors.instagramUsername.message}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="row mb-6">
              <label className="col-lg-4 col-form-label fw-semibold fs-6">{t("InstagramPassword")}</label>
              <div className="col-lg-8 fv-row" data-kt-password-meter="true">
                <div class="position-relative mb-2">
                  <input
                    type="password"
                    placeholder={t("InstagramPassword")}
                    name="instagramPassword"
                    autoComplete="off"
                    className={`form-control form-control-lg ${errors.instagramPassword ? "border-danger" : ""}`}
                    {...register("instagramPassword", {
                      required: t("InstagramPasswordIsRequired"),
                    })}
                  />
                  <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                    <i class="bi bi-eye-slash fs-2"></i>
                    <i class="bi bi-eye fs-2 d-none"></i>
                  </span>
                </div>
                {errors.instagramUsername && (
                  <div className="fv-plugins-message-container invalid-feedback">
                    <div data-field="instagramUsername" data-validator="notEmpty">
                      {errors.instagramUsername.message}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="card-footer py-6 px-9">
            <div className="d-inline-flex mt-1">
              <button type="button" className="btn btn-outline-danger btn-outline me-2" onClick={onDelete}>
                <i className="fonticon-trash-bin fs-2"></i> {t("DeleteAccount")}
              </button>
            </div>
            <div className="float-end">
              <div className="d-inline-flex mt-1">
                <button type="button" className="btn btn-primary me-2" data-bs-toggle="modal" data-bs-target="#modal_change_password">
                  <i className="fonticon-password fs-3"></i>
                  {t("ChangePassword")}
                </button>
              </div>
              <div className="d-inline-flex mt-1">
                <button id="saveButton" type="submit" className="btn btn-primary" disabled={!isValid}>
                  <span className="indicator-label">{t("SaveChanges")}</span>
                  <span className="indicator-progress">
                    {t("EditingUser")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="modal fade" id="modal_change_password" tabIndex="-1" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <h2>{t("ChangePassword")}</h2>
              <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                <span className="svg-icon svg-icon-1">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="modal-body py-lg-10 px-lg-10">
              <form onSubmit={handleSubmitChangePassword(onChangePasswordSubmit)} className="form w-100" noValidate="novalidate" action="#">
                <div className="row">
                  <div className="col-lg-12 fv-row mb-3" data-kt-password-meter="true">
                    <div class="position-relative mb-2">
                      <input
                        type="password"
                        placeholder={t("CurrentPassword")}
                        name="currentPassword"
                        autoComplete="off"
                        className={`form-control bg-transparent ${errorsChangePassword.currentPassword ? "border-danger" : ""}`}
                        {...registerChangePassword("currentPassword", {
                          required: t("CurrentPasswordIsRequired"),
                        })}
                      />
                      <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2 d-none"></i>
                      </span>
                    </div>
                    {errorsChangePassword.currentPassword && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="confirmPassword" data-validator="notEmpty">
                          {errorsChangePassword.currentPassword.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-12 fv-row mb-3" data-kt-password-meter="true">
                    <div class="position-relative mb-2">
                      <input
                        type="password"
                        placeholder={t("NewPassword")}
                        name="newPassword"
                        autoComplete="off"
                        className={`form-control bg-transparent ${errorsChangePassword.newPassword ? "border-danger" : ""}`}
                        {...registerChangePassword("newPassword", {
                          required: t("NewPasswordIsRequired"),
                        })}
                      />
                      <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2 d-none"></i>
                      </span>
                    </div>
                    {errorsChangePassword.newPassword && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="newPassword" data-validator="notEmpty">
                          {errorsChangePassword.newPassword.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 fv-row mb-3" data-kt-password-meter="true">
                    <div class="position-relative mb-2">
                      <input
                        type="password"
                        placeholder={t("ConfirmNewPassword")}
                        name="confirmPassword"
                        autoComplete="off"
                        className={`form-control bg-transparent ${errorsChangePassword.confirmPassword ? "border-danger" : ""}`}
                        {...registerChangePassword("confirmPassword", {
                          required: t("ConfirmNewPasswordIsRequired"),
                        })}
                      />
                      <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                        <i class="bi bi-eye-slash fs-2"></i>
                        <i class="bi bi-eye fs-2 d-none"></i>
                      </span>
                    </div>
                    {errorsChangePassword.confirmPassword && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="confirmPassword" data-validator="notEmpty">
                          {errorsChangePassword.confirmPassword.message}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-grid mb-10">
                  <button id="changePasswordButton" type="submit" className="btn btn-primary" disabled={!isValidChangePassword}>
                    <span className="indicator-label">{t("ChangePassword")}</span>
                    <span className="indicator-progress">
                      {t("ChangingPassword")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfileView;
