import { createContext, useContext } from "react";

import themeStore from "./ThemeStore";

const store = {
  themeStore: themeStore
};

export const StoreContext = createContext(store);

export const useStore = () => {
  return useContext(StoreContext);
};

export default store;
