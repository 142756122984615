import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      Business: "Business",
      Both: "Both",
      Personal: "Personal",
      Male: "Male",
      DoesntMatter: "Doesn't matter",
      Female: "Female",
      FollowersTheyHave: "Followers they have",
      FollowingsTheyHave: "Followings they have",
      AverageLikesPerPost: "Average Likes per Post",
      Age: "Average Likes per Post",
      LocationTargeting: "Location Targeting",
      LocationTargetingPlaceholder: "Type your location targetings...",
      Interests: "Interests",
      InterestsPlaceholder: "Type your interests...",
      SaveChanges: "Save changes",
      SavingChanges: "Saving changes...",
      SuccessfullyUpdatedTargetSettings: "You've successfully updated target settings!",
      SearchUser: "Search user",
      ShowAll: "Show All",
      OnlyActive: "Only Active",
      OnlyInactive: "Only Inactive",
      User: "User",
      Phone: "Phone",
      City: "City",
      Address: "Address",
      Active: "Active",
      Actions: "Actions",
      NoResultsFound: "No results found.",
      FullName: "Full Name",
      Email: "Email",
      InstagramUsername: "Instagram Username",
      InstagramPassword: "Instagram Password",
      UserDetails: "{{firstName}} {{lastName}}'s Details",
      AreYouSureYouWantToDeleteUser: "Are you sure you want to delete user <strong>{{firstName}} {{lastName}}</strong>?",
      Yes: "Yes",
      No: "No",
      UserSuccessfullyDeleted: "User <strong>{{firstName}} {{lastName}}</strong> is successfully deleted!",
      Followers: "Followers",
      Week: "Week",
      Month: "Month",
      Followings: "Followings",
      AverageLikes: "Average Likes",
      AverageComments: "Average Comments",
      EngagementRate: "Engagement Rate",
      NoHashtagsFound: "No hashtags found.",
      TopPosts: "Top Posts",
      NoPostsFetchedAtTheMoment: "No posts fetched at the moment.",
      DeleteAccount: "Delete Account",
      AreYouSureYouWantToDeleteYourAccount: "Are you sure you want to delete your account? This action cannot be undone.",
      YourAccountHasBeenSuccessfullyDeleted: "Your account has been successfully deleted.",
      Dashboard: "Dashboard",
      TargetingSettings: "Targeting Settings",
      Users: "Users",
      SignOut: "Sign Out",
      MyProfile: "My Profile",
      Light: "Light",
      Dark: "Dark",
      English: "English",
      German: "German",
      SignIn: "Sign In",
      SignInSubtitle: "Instagram Growth Made Simple",
      EmailOrPasswordInvalid: "The email address or password you entered is invalid.",
      YouHaveSuccessfullySignedUp: "Please confirm your email",
      NowYouCanLogInUsingYourRegisteredMail: "Please also check your spam folder.",
      EmailIsRequired: "Email is required.",
      EmailInvalidFormat: "Entered value does not match email format.",
      Password: "Password",
      PasswordIsRequired: "Password is required.",
      ForgotPassword: "Forgot password?",
      NotMemberYet: "Not a Member yet?",
      SignUp: "Sign Up",
      Slogan: "Branding tools designed for your business",
      SignUpSubtitle: "Instagram Growth Made Simple",
      StepXOutOfY: "Step {{currentStep}} out of {{totalSteps}}",
      FirstName: "First Name",
      FirstNameIsRequired: "First Name is required.",
      LastName: "Last Name",
      LastNameIsRequired: "Last Name is required.",
      CityIsRequired: "City is required.",
      AddressIsRequired: "Address is required.",
      PhoneIsRequired: "Phone is required.",
      PasswordRules: "Must be at least 6 characters and a combination of upper and lower case letters, numbers and special characters.",
      RepeatPassword: "Repeat Password",
      RepeatPasswordIsRequired: "Repeat Password is required.",
      YourPasswordsDoNotMatch: "Your passwords do not match.",
      Next: "Next",
      PaymentStepInfo: "Here goes payment step",
      InstagramUsernameIsRequired: "Instagram Username is required.",
      InstagramPasswordIsRequired: "Instagram Password is required.",
      YouNeedToAcceptTerms: "You need to accept terms.",
      IAccept: "I Accept the",
      Terms: "Terms",
      SigningUp: "Signing up...",
      AlreadyHaveAnAccount: "Already have an Account?",
      DashboardPageTitle: "Dashboard",
      DashboardPageDescription: "Here you can track your IG profile data",
      TargetSettingsPageTitle: "Target Settings",
      TargetSettingsPageDescription: "Here you can set your target settings",
      UsersPageTitle: "Users",
      UsersPageDescription: "Users list",
      UserDashboardPageTitle: "{{firstName}} {{lastName}}'s Dashboard",
      UserDashboardPageDescription: "Here you can track {{firstName}}'s IG profile data",
      MyProfilePageTitle: "My Profile",
      MyProfilePageDescription: "",
      AccountTargeting: "Account Targeting",
      AccountTargetingPlaceholder: "Type your account targetings...",
      AddUser: "Add User",
      UserSuccessfullyAdded: "User <strong>{{firstName}} {{lastName}}</strong> is successfully added!",
      Complete: "Complete",
      ClickHereToBuyPremiumGrowth: "Click here to buy Premium Growth",
      AddingUser: "Adding user...",
      EditUser: "Edit User",
      EditingUser: "Editing user...",
      UserSuccessfullyEdited: "User <strong>{{firstName}} {{lastName}}</strong> is successfully edited!",
      YourProfileSuccessfullyUpdated: "Your profile has been successfully updated!",
      ChangePassword: "Change Password",
      CurrentPassword: "Current Password",
      CurrentPasswordIsRequired: "Current Password is required.",
      PasswordYouEnteredIsIncorrect: "The password you entered is incorrect.",
      NewPassword: "New Password",
      NewPasswordIsRequired: "New Password is required.",
      ConfirmNewPassword: "Confirm New Password",
      ConfirmNewPasswordIsRequired: "Confirm New Password is required.",
      NewPasswordAndConfirmNewPasswordDontMatch: "New password and confirm new password don't match.",
      ChangingPassword: "Changing password...",
      YourPasswordSuccessfullyChanged: "Your password has been successfully changed!",
      AccountBlacklist: "Account Blacklist",
      AccountBlacklistPlaceholder: "Type your account blacklist...",
      StartUpPackage: "Basic",
      StartUpPackageDescription: "Kickstart your journey with the Basic Makaly Community Management. Ideal for beginners eager to take their first steps in community engagement",
      PremiumPackage: "Pro",
      PremiumPackageDescription: "Accelerate your success with the Pro Makaly Community Management. Perfect for professionals looking to maximize their community strategies.",
      SubscribeStartUpPackage: "Start now!",
      SubscribePremiumPackage: "Start now!",
      PerMonth: "per month",
      StartCampaign: "Start Campaign",
      AlmostThere: "Almost There",
      VerifyData: "Please verify your Instagram username, password, and goals to ensure accuracy.",
      MadePayment: "Haven't made a payment yet?",
      AddPaymentInformation: "Add Payment Information",
      PaymentAlreadyMade: "Payment Already Made",
      CampaignStartSuccessfully: "Your payment information has been successfully added!",
      InstagramGrowth: "Instagram Growth. Easy!"
    },
  },
  de: {
    translation: {
      Business: "Business",
      Both: "Beide",
      Personal: "Persönlich",
      Male: "Männlich",
      DoesntMatter: "Nicht relevant",
      Female: "Weiblich",
      FollowersTheyHave: "Anzahl der gefolgten Accounts",
      FollowingsTheyHave: "Anzahl der Follower",
      AverageLikesPerPost: "Durchschnittliche Likes pro Posts",
      Age: "Alter",
      LocationTargeting: "Geografisches Targeting",
      LocationTargetingPlaceholder: "Gebe die Städte oder Länder deiner Zielgruppe ein...",
      Interests: "Interessen",
      InterestsPlaceholder: "Gebe mögliche Interessen deine Zielgruppe ein...",
      SaveChanges: "Sichere Änderungen",
      SavingChanges: "Speichert...",
      SuccessfullyUpdatedTargetSettings: "Du hast die Targetierungseinstellungen erfolgreich geändert",
      SearchUser: "Suche Nutzer",
      ShowAll: "Alle",
      OnlyActive: "Nur Aktive",
      OnlyInactive: "Nur Deaktivierte",
      User: "User",
      Phone: "Telefonnummer",
      City: "Stadt",
      Address: "Addresse",
      Active: "Active",
      Actions: "Aktionen",
      NoResultsFound: "Kein Ergebnis gefunden.",
      FullName: "Voller Namen",
      Email: "E-Mail",
      InstagramUsername: "Instagram Username",
      InstagramPassword: "Instagram Passwort",
      UserDetails: "{{firstName}} {{lastName}}'s Details",
      AreYouSureYouWantToDeleteUser: "Willst du den User sicher löschen: <strong>{{firstName}} {{lastName}}</strong>?",
      Yes: "Ja",
      No: "Nein",
      UserSuccessfullyDeleted: "User <strong>{{firstName}} {{lastName}}</strong> wurde erfolgreich gelöscht.",
      Followers: "Follower",
      Week: "Woche",
      Month: "Monat",
      Followings: "Followings",
      AverageLikes: "Durchschnittliche Likes",
      AverageComments: "Durchschnittliche Kommentare",
      EngagementRate: "Engagement Rate",
      NoHashtagsFound: "Keine Hashtags wurden gefunden.",
      TopPosts: "Top Posts",
      NoPostsFetchedAtTheMoment: "Es wurden bis jetzt keine Posts geladen.",
      DeleteAccount: "Account löschen",
      AreYouSureYouWantToDeleteYourAccount: "Bist du sicher, dass du deinen Account löschen möchtest? Dieser Schritt kann nicht rückgängig gemacht werden.",
      YourAccountHasBeenSuccessfullyDeleted: "Dein Account wurde erfolgreich gelöscht.",
      Dashboard: "Dashboard",
      TargetingSettings: "Targetierungsoptionen",
      Users: "Users",
      SignOut: "Abmelden ",
      MyProfile: "Mein Profil",
      Light: "Light",
      Dark: "Dark",
      English: "Englisch",
      German: "Deutsch",
      SignIn: "Anmelden",
      SignInSubtitle: "Deine Kampagnen",
      EmailOrPasswordInvalid: "Der Username oder das Passwort sind ungültig.",
      YouHaveSuccessfullySignedUp: "Bitte bestätige deine E-Mail",
      NowYouCanLogInUsingYourRegisteredMail: "Überprüfe bitte auch deinen Spam Ordner.",
      EmailIsRequired: "E-Mail wird benötigt.",
      EmailInvalidFormat: "Die E-Mail ist ungültig.",
      Password: "Passwort",
      PasswordIsRequired: "Das Passwort wird benötigt.",
      ForgotPassword: "Passwort vergessen?",
      NotMemberYet: "Nocht nicht registriert?",
      SignUp: "Jetzt registrieren!",
      Slogan: "Branding tools designed for your business",
      SignUpSubtitle: "Instagram Growth Made Simple",
      StepXOutOfY: "Schritt {{currentStep}} von {{totalSteps}}",
      FirstName: "Vornamen",
      FirstNameIsRequired: " Bitte gebe deinen Vornamen an.",
      LastName: "Name",
      LastNameIsRequired: " Bitte gebe deinen Namen an.",
      CityIsRequired: "Deine Stadt wird benötigt.",
      AddressIsRequired: "Bitte gebe deine Adresse an.",
      PhoneIsRequired: " Bitte gebe deine Telefonnummer an.",
      PasswordRules: "Dein Passwort muss mindestens 6-stellig sein und  eine Mischung aus Groß- und Kleinbuchstaben, Nummern und Sonderzeichen sein.",
      RepeatPassword: "Wiederhole dein Passwort",
      RepeatPasswordIsRequired: "Bitte wiederhole dein Passwort.",
      YourPasswordsDoNotMatch: "Deine Passwörter stimmen nicht  überein.",
      Next: "Next",
      PaymentStepInfo: "Zahlungsschritt:",
      InstagramUsernameIsRequired: "Dein Instagram Username wird benötigt.",
      InstagramPasswordIsRequired: " Dein Instagram Passwort wird benötigt.",
      YouNeedToAcceptTerms: "Bitte bestätige unsere AGBs.",
      IAccept: "Ich bestätige die",
      Terms: "AGBs",
      SigningUp: "Meldet dich an...",
      AlreadyHaveAnAccount: "Hast du bereits einen Account?",
      DashboardPageTitle: "Dashboard",
      DashboardPageDescription: "Hier kannst du deine Insights tracken",
      TargetSettingsPageTitle: "Targetierungsoptionen",
      TargetSettingsPageDescription: "Stelle hier deine Targetierungseinstellungen ein ",
      UsersPageTitle: "Nutzer",
      UsersPageDescription: "Nutzerliste",
      UserDashboardPageTitle: "{{firstName}} {{lastName}}'s Dashboard",
      UserDashboardPageDescription: "Tracke hier {{firstName}}'s IG Profil Daten",
      MyProfilePageTitle: "Mein Profil",
      MyProfilePageDescription: "",
      AccountTargeting: "Accounts mit passender Zielgruppe",
      AccountTargetingPlaceholder: "Geben Sie Accounts mit einer passenden Zielgruppe ein...",
      AddUser: "Benutzer hinzufügen",
      UserSuccessfullyAdded: "Benutzer <strong>{{firstName}} {{lastName}}</strong> wurde erfolgreich hinzugefügt!",
      Complete: "Vollständig",
      ClickHereToBuyPremiumGrowth: "Klicken Sie hier, um Premium Growth zu kaufen",
      AddingUser: "Benutzer hinzufügen...",
      EditUser: "Benutzer bearbeiten",
      EditingUser: "Benutzer bearbeiten",
      UserSuccessfullyEdited: "Benutzer <strong>{{firstName}} {{lastName}}</strong> wurde erfolgreich bearbeitet!",
      YourProfileSuccessfullyUpdated: "Dein Profil wurde erfolgreich aktualisiert!",
      ChangePassword: "Passwort ändern",
      CurrentPassword: "Aktuelles Passwort",
      CurrentPasswordIsRequired: "Aktuelles Passwort ist erforderlich.",
      PasswordYouEnteredIsIncorrect: "Das eingegebene Passwort ist falsch.",
      NewPassword: "Neues Passwort",
      NewPasswordIsRequired: "Neues Passwort erforderlich.",
      ConfirmNewPassword: "Neues Passwort bestätigen",
      ConfirmNewPasswordIsRequired: "Neues Passwort bestätigen ist erforderlich.",
      NewPasswordAndConfirmNewPasswordDontMatch: "Neues Passwort und neues Passwort bestätigen stimmen nicht überein.",
      ChangingPassword: "Passwort ändern...",
      YourPasswordSuccessfullyChanged: "Ihr Passwort wurde erfolgreich geändert!",
      AccountBlacklist: "Accounts-Blacklist",
      AccountBlacklistPlaceholder: "Geben Sie Ihre Accounts-Blacklist ein...",
      StartUpPackage: "Basic",
      StartUpPackageDescription: "Starte jetzt mit dem Basic Makaly Community Management. Ideal für Einsteiger, um erste Schritte in der Community-Pflege zu machen.",
      PremiumPackage: "Pro",
      PremiumPackageDescription: "Beschleunige deinen Erfolg mit dem Pro Makaly Community Management. Perfekt für Profis, die ihre Community-Strategien maximieren wollen.",
      SubscribeStartUpPackage: "Starte jetzt!",
      SubscribePremiumPackage: "Vollgaß",
      PerMonth: "pro Monat",
      ConfirmYourEmail: "Bitte bestätige deine Email",
      ConfirmEmail: "E-Mail bestätigen",
      StartCampaign: "Kampagne starten",
      AlmostThere: "Fast Geschafft",
      VerifyData: "Bitte überprüfe kurz Deinen Instagram-Benutzernamen, Dein Passwort und Deine Zielvorstellungen, um sicherzustellen, dass alles stimmt.",
      MadePayment: "Noch nicht bezahlt?",
      AddPaymentInformation: "Zahlungsinformation hinzufügen",
      PaymentAlreadyMade: "Schon passiert",
      CampaignStartSuccessfully: "Deine Zahlungsinformationen wurden erfolgreich hinterlegt.",
      InstagramGrowth: "Instagram Wachstum. Einfach!"
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("language") || "de",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
