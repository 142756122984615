import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth/AuthService";
import { useTranslation } from "react-i18next";
import UsersService from "../../services/UsersService";

const SignUpView = (props) => {
  const {
    register: step1Register,
    handleSubmit: step1HandleSubmit,
    watch: step1Watch,
    formState: { errors: step1Errors, isValid: step1IsValid },
  } = useForm({ mode: "onChange" });

  const {
    register: step3Register,
    handleSubmit: step3HandleSubmit,
    formState: { errors: step3Errors, isValid: step3IsValid },
  } = useForm({ mode: "onChange" });

  const navigate = useNavigate();
  const { t } = useTranslation();
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState();

  useEffect(() => {
    document.body.style.backgroundImage = "url('assets/media/auth/bg12.jpg')";
    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  useEffect(() => {
    if (step > 2) {
      const fetchData = async () => {
        var completeButton = document.getElementById("completeButton");
        completeButton.setAttribute("disabled", "disabled");
        completeButton.setAttribute("data-kt-indicator", "on");
        const newUser = await AuthService.signUp(formData);
        if (newUser) {
          UsersService.scrapeData(newUser);
        }
        completeButton.removeAttribute("data-kt-indicator");
        completeButton.removeAttribute("disabled");
        navigate("/sign-in", {
          state: { firstName: formData.firstName, lastName: formData.lastName },
        });
      };
      fetchData();
    }
    if (step === 1 || step === 2) {
      if (window.KTPasswordMeter) {
        window.KTPasswordMeter.createInstances();
      }
    }
  }, [step, formData, navigate]);

  const onSubmit = async (stepFormData) => {
    setFormData((currentFormData) => ({
      ...currentFormData,
      ...stepFormData,
    }));
    setStep(step + 1);
  };

  return (
    <>
      <div className="d-flex flex-column flex-root" id="kt_app_root">
        <div className="d-flex flex-column flex-column-fluid flex-lg-row">
          <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
            <div className="d-flex flex-center flex-lg-start flex-column">
            </div>
          </div>
          <div className="d-flex flex-center w-lg-50 p-10">
            <div className="card rounded-3 w-md-550px">
              <div className="card-body p-10 p-lg-20">
                <div className="text-center mb-11">
                  <h1 className="text-dark fw-bolder mb-3">{t("SignUp")}</h1>
                  <div className="text-gray-500 fw-semibold fs-6">{t("SignUpSubtitle")}</div>
                </div>
                <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">{t("StepXOutOfY", { currentStep: step > 2 ? 2 : step, totalSteps: 2 })}</span>
                </div>
                {step === 1 && (
                  <>
                    <form onSubmit={step1HandleSubmit(onSubmit)} className="form w-100" noValidate="novalidate" action="#">
                      <div className="row mb-8">
                        <div className="col-lg-6 fv-row mb-md-3 mb-lg-0">
                          <input
                            type="text"
                            placeholder={t("FirstName")}
                            name="firstName"
                            autoComplete="off"
                            className={`form-control bg-transparent ${step1Errors.firstName ? "border-danger" : ""}`}
                            {...step1Register("firstName", {
                              required: t("FirstNameIsRequired"),
                            })}
                          />
                          {step1Errors.firstName && (
                            <div className="fv-plugins-message-container invalid-feedback">
                              <div data-field="firstName" data-validator="notEmpty">
                                {step1Errors.firstName.message}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 fv-row">
                          <input
                            type="text"
                            placeholder={t("LastName")}
                            name="lastName"
                            autoComplete="off"
                            className={`form-control bg-transparent ${step1Errors.lastName ? "border-danger" : ""}`}
                            {...step1Register("lastName", {
                              required: t("LastNameIsRequired"),
                            })}
                          />
                          {step1Errors.lastName && (
                            <div className="fv-plugins-message-container invalid-feedback">
                              <div data-field="lastName" data-validator="notEmpty">
                                {step1Errors.lastName.message}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
 
                      <div className="row mb-8">
                        <div className="fv-row">
                          <input
                            type="text"
                            placeholder={t("Email")}
                            name="email"
                            autoComplete="off"
                            className={`form-control bg-transparent ${step1Errors.email ? "border-danger" : ""}`}
                            {...step1Register("email", {
                              required: t("EmailIsRequired"),
                              pattern: {
                                value: /\S+@\S+\.\S+/,
                                message: t("EmailInvalidFormat"),
                              },
                            })}
                          />
                          {step1Errors.email && (
                            <div className="fv-plugins-message-container invalid-feedback">
                              <div data-field="email" data-validator="notEmpty">
                                {step1Errors.email.message}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="row mb-8">
                        <div className="col-lg-6 fv-row" data-kt-password-meter="true">
                          <div class="position-relative mb-3">
                            <input
                              type="password"
                              placeholder={t("Password")}
                              name="password"
                              autoComplete="off"
                              className={`form-control bg-transparent ${step1Errors.password ? "border-danger" : ""}`}
                              {...step1Register("password", {
                                required: t("PasswordIsRequired"),
                                pattern: {
                                  value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{6,}$/,
                                  message: t("PasswordRules"),
                                },
                              })}
                            />
                            <span className="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                              <i className="bi bi-eye-slash fs-2"></i>
                              <i className="bi bi-eye fs-2 d-none"></i>
                            </span>
                          </div>

                          {step1Errors.password && (
                            <div className="fv-plugins-message-container invalid-feedback">
                              <div data-field="password" data-validator="notEmpty">
                                {step1Errors.password.message}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 fv-row" data-kt-password-meter="true">
                          <div class="position-relative mb-3">
                            <input
                              type="password"
                              placeholder={t("RepeatPassword")}
                              name="repeatPassword"
                              autoComplete="off"
                              className={`form-control bg-transparent ${step1Errors.repeatPassword ? "border-danger" : ""}`}
                              {...step1Register("repeatPassword", {
                                required: t("RepeatPasswordIsRequired"),
                                validate: (value) => {
                                  if (step1Watch("password") !== value) {
                                    return t("YourPasswordsDoNotMatch");
                                  }
                                },
                              })}
                            />
                            <span className="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                              <i className="bi bi-eye-slash fs-2"></i>
                              <i className="bi bi-eye fs-2 d-none"></i>
                            </span>
                          </div>
                          {step1Errors.repeatPassword && (
                            <div className="fv-plugins-message-container invalid-feedback">
                              <div data-field="repeatPassword" data-validator="notEmpty">
                                {step1Errors.repeatPassword.message}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="d-grid mb-10">
                        <button type="submit" id="kt_sign_up_submit" className="btn btn-primary" disabled={!step1IsValid}>
                          {t("Next")}
                        </button>
                      </div>
                    </form>
                  </>
                )}
                {step >= 2 && (
                  <>
                    <form onSubmit={step3HandleSubmit(onSubmit)} className="form w-100" noValidate="novalidate" action="#">
                      <div className="fv-row mb-8">
                        <input
                          type="text"
                          placeholder={t("InstagramUsername")}
                          name="instagramUsername"
                          autoComplete="off"
                          className={`form-control bg-transparent ${step3Errors.instagramUsername ? "border-danger" : ""}`}
                          {...step3Register("instagramUsername", {
                            required: t("InstagramUsernameIsRequired"),
                          })}
                        />
                        {step3Errors.instagramUsername && (
                          <div className="fv-plugins-message-container invalid-feedback">
                            <div data-field="instagramUsername" data-validator="notEmpty">
                              {step3Errors.instagramUsername.message}
                            </div>
                          </div>
                        )}
                      </div>
                     
                      <div className="fv-row mb-8">
                        <label className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="toc"
                            value="1"
                            {...step3Register("acceptTerms", {
                              required: t("YouNeedToAcceptTerms"),
                            })}
                          />
                          <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                            {t("IAccept")}
                            <a href="https://www.makaly.de/agbs" target="_blank" className="ms-1 link-primary">
                              {t("Terms")}
                            </a>
                          </span>
                        </label>
                        {step3Errors.acceptTerms && (
                          <div className="fv-plugins-message-container invalid-feedback">
                            <div data-field="acceptTerms" data-validator="notEmpty">
                              {step3Errors.acceptTerms.message}
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="d-grid mb-10">
                        <button id="completeButton" type="submit" className="btn btn-primary" disabled={!step3IsValid}>
                          <span className="indicator-label">{t("Complete")}</span>
                          <span className="indicator-progress">
                            {t("SigningUp")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                          </span>
                        </button>
                      </div>
                    </form>
                  </>
                )}

                <div className="text-gray-500 text-center fw-semibold fs-6">
                  {t("AlreadyHaveAnAccount")}
                  <a href="#!" onClick={() => navigate("/sign-in")} className="link-primary fw-semibold">
                    &nbsp;{t("SignIn")}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpView;
