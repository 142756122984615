import React from "react";
import { InstagramEmbed } from "react-social-media-embed";
import { useParams } from "react-router-dom";

const EmbedIGPostView = () => {
  let { short_code } = useParams();

  return <InstagramEmbed url={`https://www.instagram.com/p/${short_code}`} captioned />;
};

export default EmbedIGPostView;
