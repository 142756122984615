import React, { useState, useEffect } from "react";
import UsersService from "../../services/UsersService";
import wNumb from "wnumb";
import debounce from "debounce";
import TargetOptionService from "../../services/TargetOptionService";
import Pager from "../../utils/Pager";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import AuthService from "../../services/auth/AuthService";

const UsersView = (props) => {
  const [users, setUsers] = useState();
  const [searchParams, setSearchParams] = useState({
    page: 1,
    pageSize: 10,
    totalPages: 10,
    searchTerm: null,
    isActive: null,
    refreshed: 0,
  });
  const [userDetails, setUserDetails] = useState();
  const [isActiveFilter, setIsActiveFilter] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    register: registerAdd,
    handleSubmit: handleSubmitAdd,
    watch: watchAdd,
    reset: resetAdd,
    formState: { errors: errorsAdd, isValid: isValidAdd },
  } = useForm({ mode: "onChange" });

  const {
    register: registerEdit,
    handleSubmit: handleSubmitEdit,
    reset: resetEdit,
    setValue: setValueEdit,
    formState: { errors: errorsEdit, isValid: isValidEdit },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (window.KTPasswordMeter) {
      window.KTPasswordMeter.createInstances();
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      if (isActiveFilter !== searchParams.isActive) {
        setIsActiveFilter(searchParams.isActive);
        setSearchParams((previousSearchParams) => ({
          ...previousSearchParams,
          page: 1,
        }));
      }
      var responseData = await UsersService.getByParameters(searchParams);
      setUsers(responseData.result);
      setSearchParams((previousSearchParams) => ({
        ...previousSearchParams,
        totalPages: responseData.totalPages,
      }));
    };
    fetchData();

    var followersSlider = document.querySelector("#followers");
    var followingsSlider = document.querySelector("#followings");
    var averageLikesPerPostSlider = document.querySelector("#averageLikesPerPost");
    var ageSlider = document.querySelector("#age");

    if (!followersSlider.noUiSlider) {
      window.noUiSlider.create(followersSlider, {
        start: [1000, 5000],
        connect: true,
        tooltips: true,
        step: 500,
        range: {
          min: 0,
          max: 5000,
        },
        format: wNumb({
          decimals: 0,
        }),
      });
      followersSlider.setAttribute("disabled", true);
    }

    if (!followingsSlider.noUiSlider) {
      window.noUiSlider.create(followingsSlider, {
        start: [1000, 5000],
        connect: true,
        tooltips: true,
        step: 500,
        range: {
          min: 0,
          max: 5000,
        },
        format: wNumb({
          decimals: 0,
        }),
      });
      followingsSlider.setAttribute("disabled", true);
    }

    if (!averageLikesPerPostSlider.noUiSlider) {
      window.noUiSlider.create(averageLikesPerPostSlider, {
        start: [100, 1000],
        connect: true,
        tooltips: true,
        step: 100,
        range: {
          min: 0,
          max: 1000,
        },
        format: wNumb({
          decimals: 0,
        }),
      });
      averageLikesPerPostSlider.setAttribute("disabled", true);
    }

    if (!ageSlider.noUiSlider) {
      window.noUiSlider.create(ageSlider, {
        start: [25, 40],
        connect: true,
        tooltips: true,
        range: {
          min: 18,
          max: 65,
        },
        format: wNumb({
          decimals: 0,
        }),
      });
      ageSlider.setAttribute("disabled", true);
    }

    var input1 = document.querySelector("#locationTargeting");
    var input2 = document.querySelector("#interests");
    var input3 = document.querySelector("#accountTargeting");
    var input4 = document.querySelector("#accountBlacklist");

    new window.Tagify(input1);
    new window.Tagify(input2);
    new window.Tagify(input3);
    new window.Tagify(input4);
  }, [searchParams.searchTerm, searchParams.isActive, searchParams.page, searchParams.refreshed]);

  const onIsActiveChange = async (e) => {
    var userId = e.target.dataset.id;
    if (!userId) return;
    if (e.target.checked) {
      var isRoleAssigned = await UsersService.assignRole(userId, "PremiumUser");
      if (!isRoleAssigned) {
        e.target.checked = false;
      }
    } else {
      var isRoleRemoved = await UsersService.removeRole(userId, "PremiumUser");
      if (!isRoleRemoved) {
        e.target.checked = true;
      }
    }
  };

  const onSearchChange = async (e) => {
    setSearchParams((previousParams) => ({
      ...previousParams,
      page: 1,
      searchTerm: e.target.value,
    }));
  };

  const onDelete = async (user) => {
    window.Swal.fire({
      html: t("AreYouSureYouWantToDeleteUser", { firstName: user.firstName, lastName: user.lastName }),
      icon: "question",
      buttonsStyling: false,
      showCancelButton: true,
      confirmButtonText: t("Yes"),
      cancelButtonText: t("No"),
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        var isDeleted = await UsersService.deleteUser(user.id);
        if (isDeleted) {
          setSearchParams((previousParams) => ({
            ...previousParams,
            refreshed: previousParams.refreshed + 1,
          }));
          window.Swal.fire({
            html: t("UserSuccessfullyDeleted", { firstName: user.firstName, lastName: user.lastName }),
            icon: "success",
            customClass: {
              confirmButton: "btn btn-primary",
              cancelButton: "btn btn-danger",
            },
          });
        }
      }
    });
  };

  const onUserDetails = async (user) => {
    var userTargetSettings = await TargetOptionService.getByUserId(user.id);
    if (!userTargetSettings) {
      userTargetSettings = {
        profileType: null,
        minFollowers: 0,
        maxFollowers: 0,
        minFollowings: 0,
        maxFollowings: 0,
        minAvgLikePerPost: 0,
        maxAvgLikePerPost: 0,
        gender: "not-selected",
        minAge: 18,
        maxAge: 18,
        locationTargeting: "",
        interestTargeting: "",
        accountTargeting: "",
        accountBlacklist: "",
      };
    }

    setUserDetails({
      ...user,
      userTargetSettings,
    });
    var followersSlider = document.querySelector("#followers");
    var followingsSlider = document.querySelector("#followings");
    var averageLikesPerPostSlider = document.querySelector("#averageLikesPerPost");
    var ageSlider = document.querySelector("#age");

    if (followersSlider.noUiSlider) {
      followersSlider.noUiSlider.set([userTargetSettings.minFollowers, userTargetSettings.maxFollowers]);
    }

    if (followingsSlider.noUiSlider) {
      followingsSlider.noUiSlider.set([userTargetSettings.minFollowings, userTargetSettings.maxFollowings]);
    }

    if (averageLikesPerPostSlider.noUiSlider) {
      averageLikesPerPostSlider.noUiSlider.set([userTargetSettings.minAvgLikePerPost, userTargetSettings.maxAvgLikePerPost]);
    }

    if (ageSlider.noUiSlider) {
      ageSlider.noUiSlider.set([userTargetSettings.minAge, userTargetSettings.maxAge]);
    }
  };

  const onIsActiveFilterChange = (e) => {
    const value = e.target.value;
    setSearchParams((previousSearchParams) => ({
      ...previousSearchParams,
      isActive: value === "" ? null : value === "true",
    }));
  };

  const onPageChanged = (newPage) => {
    setSearchParams((previousSearchParams) => ({
      ...previousSearchParams,
      page: newPage + 1,
    }));
  };

  const onAddSubmit = async (formData) => {
    const addUser = async () => {
      var completeButton = document.getElementById("completeButton");
      completeButton.setAttribute("disabled", "disabled");
      completeButton.setAttribute("data-kt-indicator", "on");
      const newUser = await AuthService.signUp(formData);
      if (newUser) {
        UsersService.scrapeData(newUser);
      }
      completeButton.removeAttribute("data-kt-indicator");
      completeButton.removeAttribute("disabled");
      window.$("#modal_add_user").modal("hide");
      window.Swal.fire({
        html: t("UserSuccessfullyAdded", { firstName: formData.firstName, lastName: formData.lastName }),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      });
      setSearchParams((previousParams) => ({
        ...previousParams,
        refreshed: previousParams.refreshed + 1,
      }));
      resetAdd();
    };
    addUser();
  };

  const onUserEdit = (user) => {
    setValueEdit("id", user.id);
    setValueEdit("firstName", user.firstName);
    setValueEdit("lastName", user.lastName);
    setValueEdit("email", user.email);
    setValueEdit("instagramUsername", user.instagramUsername);
    setValueEdit("instagramPassword", user.instagramPassword);
    setValueEdit("paymentMethod", user.paymentMethod);
    setValueEdit("subscriptionPlan", user.subscriptionPlan);
    setValueEdit("isPremium", user.isPremium);
    setValueEdit("dateCreated", user.dateCreated);
  };

  const onEditSubmit = (formData) => {
    const editUser = async () => {
      var saveButton = document.getElementById("saveButton");
      saveButton.setAttribute("disabled", "disabled");
      saveButton.setAttribute("data-kt-indicator", "on");
      await UsersService.editUser(formData);
      saveButton.removeAttribute("data-kt-indicator");
      saveButton.removeAttribute("disabled");
      window.$("#modal_edit_user").modal("hide");
      window.Swal.fire({
        html: t("UserSuccessfullyEdited", { firstName: formData.firstName, lastName: formData.lastName }),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      });
      setSearchParams((previousParams) => ({
        ...previousParams,
        refreshed: previousParams.refreshed + 1,
      }));
      resetEdit();
    };
    editUser();
  };

  return (
    <>
      <div className="border-0">
        <div className="row">
          <div className="col-lg-5 col-md-5 col-sm-12 my-2">
            <div className="d-flex align-items-center position-relative me-3">
              <span className="svg-icon svg-icon-1 position-absolute ms-6">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1" transform="rotate(45 17.0365 15.1223)" fill="currentColor" />
                  <path
                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                    fill="currentColor"
                  />
                </svg>
              </span>
              <input
                type="text"
                data-kt-user-table-filter="search"
                className="form-control form-control-solid ps-14"
                placeholder={t("SearchUser")}
                onChange={debounce(async (e) => {
                  onSearchChange(e);
                }, 300)}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-4 col-sm-12 my-2">
            <div className="btn-group d-flex" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
              <label className={`btn btn-outline btn-color-muted btn-active-primary ${searchParams.isActive === null ? "active" : ""}`}>
                <input className="btn-check" type="radio" name="type" value={""} onChange={onIsActiveFilterChange} />
                {t("ShowAll")}
              </label>
              <label className={`btn btn-outline btn-color-muted btn-active-primary ${searchParams.isActive === true ? "active" : ""}`}>
                <input className="btn-check" type="radio" name="type" value={true} onChange={onIsActiveFilterChange} />
                {t("OnlyActive")}
              </label>
              <label className={`btn btn-outline btn-color-muted btn-active-primary ${searchParams.isActive === false ? "active" : ""}`}>
                <input className="btn-check" type="radio" name="type" value={false} onChange={onIsActiveFilterChange} />
                {t("OnlyInactive")}
              </label>
            </div>
          </div>
          <div className="col-md-3 col-md-3 col-sm-12 my-2 ">
            <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#modal_add_user">
              <span class="svg-icon svg-icon-2">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect opacity="0.5" x="11.364" y="20.364" width="16" height="2" rx="1" transform="rotate(-90 11.364 20.364)" fill="currentColor"></rect>
                  <rect x="4.36396" y="11.364" width="16" height="2" rx="1" fill="currentColor"></rect>
                </svg>
              </span>
              {t("AddUser")}
            </button>
          </div>
        </div>
      </div>
      <div className="card-body py-4">
        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 table-responsive">
            <thead>
              <tr className="text-start text-muted fw-bold fs-7 text-uppercase gs-0">
                <th className="min-w-125px">{t("User")}</th>
                <th className="min-w-175px">{t("Active")}?</th>
                <th className="text-center min-w-50px">{t("Actions")}</th>
              </tr>
            </thead>
            <tbody className="text-gray-600 fw-semibold">
              {searchParams && (!users || users.length === 0) && (
                <tr>
                  <td colSpan={6} className="text-center">
                    {t("NoResultsFound")}
                  </td>
                </tr>
              )}
              {users &&
                users.map((user, i) => {
                  return (
                    <tr key={i}>
                      <td className="d-flex align-items-center">
                        <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                          <a href="#!" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end" data-bs-toggle="modal" data-bs-target="#modal_user_details" onClick={() => onUserDetails(user)}>
                            <div className="symbol-label fs-3 bg-light text-primary">
                              {user.firstName[0]}
                              {user.lastName[0]}
                            </div>
                          </a>
                        </div>
                        <div className="d-flex flex-column">
                          <a
                            href="#!"
                            className="text-gray-800 text-hover-primary mb-1"
                            data-kt-menu-trigger="click"
                            data-kt-menu-placement="bottom-end"
                            data-bs-toggle="modal"
                            data-bs-target="#modal_user_details"
                            onClick={() => onUserDetails(user)}
                          >
                            {user.firstName} {user.lastName}
                          </a>
                          <span>{user.email}</span>
                        </div>
                      </td>
                      <td>
                        <div className="col-lg-8 d-flex align-items-center">
                          <div className="form-check form-check-solid form-switch form-check-custom fv-row">
                            {user.isPremium && <input data-id={user.id} className="form-check-input w-45px h-30px" type="checkbox" defaultChecked={true} onChange={onIsActiveChange} />}
                            {!user.isPremium && <input data-id={user.id} className="form-check-input w-45px h-30px" type="checkbox" onChange={onIsActiveChange} />}
                            <label className="form-check-label" htmlFor="allowmarketing"></label>
                          </div>
                        </div>
                      </td>

                      <td className="text-end">
                        <button
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_edit_user"
                          onClick={() => onUserEdit(user)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          title="Edit user"
                        >
                          <span class="svg-icon svg-icon-muted">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                opacity="0.3"
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M2 4.63158C2 3.1782 3.1782 2 4.63158 2H13.47C14.0155 2 14.278 2.66919 13.8778 3.04006L12.4556 4.35821C11.9009 4.87228 11.1726 5.15789 10.4163 5.15789H7.1579C6.05333 5.15789 5.15789 6.05333 5.15789 7.1579V16.8421C5.15789 17.9467 6.05333 18.8421 7.1579 18.8421H16.8421C17.9467 18.8421 18.8421 17.9467 18.8421 16.8421V13.7518C18.8421 12.927 19.1817 12.1387 19.7809 11.572L20.9878 10.4308C21.3703 10.0691 22 10.3403 22 10.8668V19.3684C22 20.8218 20.8218 22 19.3684 22H4.63158C3.1782 22 2 20.8218 2 19.3684V4.63158Z"
                                fill="currentColor"
                              />
                              <path
                                d="M10.9256 11.1882C10.5351 10.7977 10.5351 10.1645 10.9256 9.77397L18.0669 2.6327C18.8479 1.85165 20.1143 1.85165 20.8953 2.6327L21.3665 3.10391C22.1476 3.88496 22.1476 5.15129 21.3665 5.93234L14.2252 13.0736C13.8347 13.4641 13.2016 13.4641 12.811 13.0736L10.9256 11.1882Z"
                                fill="currentColor"
                              />
                              <path
                                d="M8.82343 12.0064L8.08852 14.3348C7.8655 15.0414 8.46151 15.7366 9.19388 15.6242L11.8974 15.2092C12.4642 15.1222 12.6916 14.4278 12.2861 14.0223L9.98595 11.7221C9.61452 11.3507 8.98154 11.5055 8.82343 12.0064Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>

                        <button
                          data-kt-menu-trigger="click"
                          data-kt-menu-placement="bottom-end"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_user_details"
                          onClick={() => onUserDetails(user)}
                          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1"
                          title="User details"
                        >
                          <span className="svg-icon svg-icon-3">
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor" />
                              <path
                                d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z"
                                fill="currentColor"
                              />
                              <rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor" />
                            </svg>
                          </span>
                        </button>

                        <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" title="User dashboard" onClick={() => navigate(`/user-dashboard?id=${user.id}`)}>
                          <span className="svg-icon svg-icon-muted">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path opacity="0.3" d="M14 3V21H10V3C10 2.4 10.4 2 11 2H13C13.6 2 14 2.4 14 3ZM7 14H5C4.4 14 4 14.4 4 15V21H8V15C8 14.4 7.6 14 7 14Z" fill="currentColor" />
                              <path
                                d="M21 20H20V8C20 7.4 19.6 7 19 7H17C16.4 7 16 7.4 16 8V20H3C2.4 20 2 20.4 2 21C2 21.6 2.4 22 3 22H21C21.6 22 22 21.6 22 21C22 20.4 21.6 20 21 20Z"
                                fill="currentColor"
                              />
                            </svg>
                          </span>
                        </button>

                        <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm" onClick={() => onDelete(user)} title="Delete user">
                          <span className="svg-icon svg-icon-3">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M5 9C5 8.44772 5.44772 8 6 8H18C18.5523 8 19 8.44772 19 9V18C19 19.6569 17.6569 21 16 21H8C6.34315 21 5 19.6569 5 18V9Z" fill="currentColor"></path>
                              <path opacity="0.5" d="M5 5C5 4.44772 5.44772 4 6 4H18C18.5523 4 19 4.44772 19 5V5C19 5.55228 18.5523 6 18 6H6C5.44772 6 5 5.55228 5 5V5Z" fill="currentColor"></path>
                              <path opacity="0.5" d="M9 4C9 3.44772 9.44772 3 10 3H14C14.5523 3 15 3.44772 15 4V4H9V4Z" fill="currentColor"></path>
                            </svg>
                          </span>
                        </button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
          {searchParams.totalPages > 1 && <Pager total={searchParams.totalPages} current={searchParams.page - 1} visiblePages={3} onPageChanged={onPageChanged} />}
        </div>
        <div className="modal fade" id="modal_user_details" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered mw-900px">
            <div className="modal-content">
              <div className="modal-header">
                <h2>{userDetails && t("UserDetails", { firstName: userDetails.firstName, lastName: userDetails.lastName })}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="modal-body py-lg-10 px-lg-10">
                <div className="row mb-10">
                  <div className="col fs-6">
                    <div className="fw-bold">{t("FullName")}</div>
                    <div className="text-gray-600">
                      {userDetails && userDetails.firstName} {userDetails && userDetails.lastName}
                    </div>
                  </div>
                  <div className="col fs-6">
                    <div className="fw-bold">{t("Email")}</div>
                    <div className="text-gray-600">{userDetails && userDetails.email}</div>
                  </div>
                </div>
                <div className="row mb-15">
                  <div className="col fs-6">
                    <div className="fw-bold">{t("InstagramUsername")}</div>
                    <div className="text-gray-600">{userDetails && userDetails.instagramUsername}</div>
                  </div>
                  <div className="col fs-6">
                    <div className="fw-bold">{t("InstagramPassword")}</div>
                    <div className="text-gray-600">{userDetails && userDetails.instagramPassword}</div>
                  </div>
                </div>

                <div className="row mb-20">
                  <div className="col-md-5">
                    <div className="btn-group w-100" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                      <label
                        className={`btn btn-outline disabled btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.profileType === "business" ? "active" : ""
                        }`}
                      >
                        <input className="btn-check" type="radio" name="type" value="business" />
                        {t("Business")}
                      </label>
                      <label
                        className={`btn btn-outline disabled btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.profileType === "both" ? "active" : ""
                        }`}
                      >
                        <input className="btn-check" type="radio" name="type" value="both" />
                        {t("Both")}
                      </label>
                      <label
                        className={`btn btn-outline disabled btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.profileType === "personal" ? "active" : ""
                        }`}
                      >
                        <input className="btn-check" type="radio" name="type" value="personal" />
                        {t("Personal")}
                      </label>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5">
                    <div className="btn-group w-100" data-kt-buttons="true" data-kt-buttons-target="[data-kt-button]">
                      <label
                        className={`btn btn-outline disabled  btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.gender === "male" ? "active" : ""
                        }`}
                        data-kt-button="true"
                      >
                        <input className="btn-check" type="radio" name="gender" value="male" />
                        {t("Male")}
                      </label>
                      <label
                        className={`btn btn-outline disabled btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.gender === "not-selected" ? "active" : ""
                        }`}
                        data-kt-button="true"
                      >
                        <input className="btn-check" type="radio" name="gender" value="not-selected" />
                        {t("DoesntMatter")}
                      </label>
                      <label
                        className={`btn btn-outline disabled btn-color-muted btn-active-primary ${
                          userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.gender === "female" ? "active" : ""
                        }`}
                        data-kt-button="true"
                      >
                        <input className="btn-check" type="radio" name="gender" value="female" />
                        {t("Female")}
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-md-5">
                    <label className="form-label mb-15">
                      <i className="fa fa-users fs-3"></i>&nbsp; {t("FollowersTheyHave")}
                    </label>
                    <div id="followers"></div>
                    <div className="col mt-2">
                      <span className="text-muted">0</span>
                      <span className="text-muted float-end">5000+</span>
                    </div>
                  </div>
                  <div className="col-md-2"></div>

                  <div className="col-md-5">
                    <label className="form-label mb-15">
                      <i className="fa fa-users fs-3"></i>&nbsp; {t("FollowingsTheyHave")}
                    </label>
                    <div id="followings"></div>
                    <div className="col mt-2">
                      <span className="text-muted">0</span>
                      <span className="text-muted float-end">5000+</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-20">
                  <div className="col-md-5">
                    <label className="form-label mb-15">
                      <i className="fa fa-heart fs-3"></i>&nbsp; {t("AverageLikesPerPost")}
                    </label>
                    <div id="averageLikesPerPost"></div>
                    <div className="col mt-2">
                      <span className="text-muted">0</span>
                      <span className="text-muted float-end">1000+</span>
                    </div>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5">
                    <label className="form-label mb-15">
                      <i className="fa fa-user fs-3"></i>&nbsp; {t("Age")}
                    </label>
                    <div id="age"></div>
                    <div className="col mt-2">
                      <span className="text-muted">18</span>
                      <span className="text-muted float-end">65+</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-15">
                  <div className="col-md-5">
                    <label className="form-label">
                      <i className="fa fa-location-dot fs-3"></i>&nbsp; {t("LocationTargeting")}
                    </label>
                    <input
                      className="form-control"
                      value={userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.locationTargeting ? userDetails.userTargetSettings.locationTargeting : ""}
                      id="locationTargeting"
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5">
                    <label className="form-label">
                      <i className="fa fa-question fs-3"></i>&nbsp; {t("Interests")}
                    </label>
                    <input
                      className="form-control"
                      value={userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.interestTargeting ? userDetails.userTargetSettings.interestTargeting : ""}
                      id="interests"
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="row mb-15">
                  <div className="col-md-5">
                    <label className="form-label">
                      <i className="fa fa-user fs-3"></i>&nbsp; {t("AccountTargeting")}
                    </label>
                    <input
                      className="form-control"
                      value={userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.accountTargeting ? userDetails.userTargetSettings.accountTargeting : ""}
                      id="accountTargeting"
                      disabled={true}
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5">
                    {" "}
                    <label className="form-label">
                      <i className="fa fa-user-slash fs-3"></i>&nbsp; {t("AccountBlacklist")}
                    </label>
                    <input
                      className="form-control"
                      value={userDetails && userDetails.userTargetSettings && userDetails.userTargetSettings.accountBlacklist ? userDetails.userTargetSettings.accountBlacklist : ""}
                      id="accountBlacklist"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal_add_user" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header">
                <h2>{t("AddUser")}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="modal-body py-lg-10 px-lg-10">
                <form onSubmit={handleSubmitAdd(onAddSubmit)} className="form w-100" noValidate="novalidate" action="#">
                  <div className="row">
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("FirstName")}
                          name="firstName"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.firstName ? "border-danger" : ""}`}
                          {...registerAdd("firstName", {
                            required: t("FirstNameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsAdd.firstName && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="firstName" data-validator="notEmpty">
                            {errorsAdd.firstName.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("LastName")}
                          name="lastName"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.lastName ? "border-danger" : ""}`}
                          {...registerAdd("lastName", {
                            required: t("LastNameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsAdd.lastName && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="lastName" data-validator="notEmpty">
                            {errorsAdd.lastName.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("Email")}
                          name="email"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.email ? "border-danger" : ""}`}
                          {...registerAdd("email", {
                            required: t("EmailIsRequired"),
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: t("EmailInvalidFormat"),
                            },
                          })}
                        />
                      </div>
                      {errorsAdd.email && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="email" data-validator="notEmpty">
                            {errorsAdd.email.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 fv-row mb-3" data-kt-password-meter="true">
                      <div class="position-relative mb-2">
                        <input
                          type="password"
                          placeholder={t("Password")}
                          name="password"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.password ? "border-danger" : ""}`}
                          {...registerAdd("password", {
                            required: t("PasswordIsRequired"),
                            pattern: {
                              value: /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).{6,}$/,
                              message: t("PasswordRules"),
                            },
                          })}
                        />
                        <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>

                      {errorsAdd.password && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="password" data-validator="notEmpty">
                            {errorsAdd.password.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 fv-row mb-3" data-kt-password-meter="true">
                      <div class="position-relative mb-2">
                        <input
                          type="password"
                          placeholder={t("RepeatPassword")}
                          name="repeatPassword"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.repeatPassword ? "border-danger" : ""}`}
                          {...registerAdd("repeatPassword", {
                            required: t("RepeatPasswordIsRequired"),
                            validate: (value) => {
                              if (watchAdd("password") !== value) {
                                return t("YourPasswordsDoNotMatch");
                              }
                            },
                          })}
                        />
                        <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>
                      {errorsAdd.repeatPassword && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="repeatPassword" data-validator="notEmpty">
                            {errorsAdd.repeatPassword.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("InstagramUsername")}
                          name="instagramUsername"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.instagramUsername ? "border-danger" : ""}`}
                          {...registerAdd("instagramUsername", {
                            required: t("InstagramUsernameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsAdd.instagramUsername && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="instagramUsername" data-validator="notEmpty">
                            {errorsAdd.instagramUsername.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 fv-row mb-3" data-kt-password-meter="true">
                      <div class="position-relative mb-2">
                        <input
                          type="password"
                          placeholder={t("InstagramPassword")}
                          name="instagramPassword"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.instagramPassword ? "border-danger" : ""}`}
                          {...registerAdd("instagramPassword", {
                            required: t("InstagramPasswordIsRequired"),
                          })}
                        />
                        <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>
                      {errorsAdd.instagramPassword && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="instagramPassword" data-validator="notEmpty">
                            {errorsAdd.instagramPassword.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="fv-row mb-8">
                    <label className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="toc"
                        value="1"
                        {...registerAdd("acceptTerms", {
                          required: t("YouNeedToAcceptTerms"),
                        })}
                      />
                      <span className="form-check-label fw-semibold text-gray-700 fs-base ms-1">
                        {t("IAccept")}
                        <a href="#!" className="ms-1 link-primary">
                          {t("Terms")}
                        </a>
                      </span>
                    </label>
                    {errorsAdd.acceptTerms && (
                      <div className="fv-plugins-message-container invalid-feedback">
                        <div data-field="acceptTerms" data-validator="notEmpty">
                          {errorsAdd.acceptTerms.message}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="d-grid mb-10">
                    <button id="completeButton" type="submit" className="btn btn-primary" disabled={!isValidAdd}>
                      <span className="indicator-label">{t("Complete")}</span>
                      <span className="indicator-progress">
                        {t("AddingUser")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="modal fade" id="modal_edit_user" tabIndex="-1" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered mw-500px">
            <div className="modal-content">
              <div className="modal-header">
                <h2>{t("EditUser")}</h2>
                <div className="btn btn-sm btn-icon btn-active-color-primary" data-bs-dismiss="modal">
                  <span className="svg-icon svg-icon-1">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1" transform="rotate(-45 6 17.3137)" fill="currentColor" />
                      <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)" fill="currentColor" />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="modal-body py-lg-10 px-lg-10">
                <form onSubmit={handleSubmitEdit(onEditSubmit)} className="form w-100" noValidate="novalidate" action="#">
                  <div className="row">
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("FirstName")}
                          name="firstName"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsEdit.firstName ? "border-danger" : ""}`}
                          {...registerEdit("firstName", {
                            required: t("FirstNameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsEdit.firstName && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="firstName" data-validator="notEmpty">
                            {errorsEdit.firstName.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("LastName")}
                          name="lastName"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsEdit.lastName ? "border-danger" : ""}`}
                          {...registerEdit("lastName", {
                            required: t("LastNameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsEdit.lastName && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="lastName" data-validator="notEmpty">
                            {errorsEdit.lastName.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("Email")}
                          name="email"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsAdd.email ? "border-danger" : ""}`}
                          {...registerEdit("email", {
                            required: t("EmailIsRequired"),
                            pattern: {
                              value: /\S+@\S+\.\S+/,
                              message: t("EmailInvalidFormat"),
                            },
                          })}
                        />
                      </div>
                      {errorsEdit.email && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="email" data-validator="notEmpty">
                            {errorsEdit.email.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 fv-row mb-3">
                      <div class="position-relative mb-2">
                        <input
                          type="text"
                          placeholder={t("InstagramUsername")}
                          name="instagramUsername"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsEdit.instagramUsername ? "border-danger" : ""}`}
                          {...registerEdit("instagramUsername", {
                            required: t("InstagramUsernameIsRequired"),
                          })}
                        />
                      </div>
                      {errorsEdit.instagramUsername && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="instagramUsername" data-validator="notEmpty">
                            {errorsEdit.instagramUsername.message}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="col-lg-6 fv-row mb-3" data-kt-password-meter="true">
                      <div class="position-relative mb-2">
                        <input
                          type="password"
                          placeholder={t("InstagramPassword")}
                          name="instagramPassword"
                          autoComplete="off"
                          className={`form-control bg-transparent ${errorsEdit.instagramPassword ? "border-danger" : ""}`}
                          {...registerEdit("instagramPassword", {
                            required: t("InstagramPasswordIsRequired"),
                          })}
                        />
                        <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                          <i class="bi bi-eye-slash fs-2"></i>
                          <i class="bi bi-eye fs-2 d-none"></i>
                        </span>
                      </div>
                      {errorsEdit.instagramPassword && (
                        <div className="fv-plugins-message-container invalid-feedback">
                          <div data-field="instagramPassword" data-validator="notEmpty">
                            {errorsEdit.instagramPassword.message}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-grid mb-10">
                    <button id="saveButton" type="submit" className="btn btn-primary" disabled={!isValidEdit}>
                      <span className="indicator-label">{t("SaveChanges")}</span>
                      <span className="indicator-progress">
                        {t("EditingUser")} <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UsersView;
