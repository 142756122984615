import http from "../common/api";
import AuthService from "./auth/AuthService";

class TargetOptionService {
  getByUserId = (userId) => {
    return http
      .get(`TargetOption/target-option-by-userid/${userId}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  };

  add = (targetSettings) => {
    return http
      .post("TargetOption", targetSettings)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return null;
      });
  };

  update = (targetSettings) => {
    return http
      .put(`TargetOption/${targetSettings.id}`, targetSettings)
      .then((response) => {
        return response.data.result;
      })
      .catch((err) => {
        return null;
      });
  };

  sendInternalEmails = () => {
    var currentUser = AuthService.getCurrentUser();
    return http
      .post(`TargetOption/send-internal-emails`, {}, {
        headers: { Authorization: `Bearer ${currentUser.accessToken}` },
      })
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return null;
      });
  };


  // assignRole = (userId, roleName) => {
  //   return http
  //     .post("Admin/assign-role", { userId, roleName })
  //     .then((response) => {
  //       return response.data;
  //     });
  // };

  // removeRole = (userId, roleName) => {
  //   return http
  //     .post("Admin/remove-role", { userId, roleName })
  //     .then((response) => {
  //       return response.data;
  //     });
  // };
}

export default new TargetOptionService();
