import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import wNumb from "wnumb";
import AuthService from "../../services/auth/AuthService";
import UsersService from "../../services/UsersService";
import TargetOptionService from "../../services/TargetOptionService";
import { ReactComponent as IconCards } from "./icon-cards.svg";
import "react-toastify/dist/ReactToastify.css";
import "./targetingSettings.css";

const TargetingSettingsView = (props) => {
  const { t } = useTranslation();
  const [targetSettings, setTargetSettings] = useState({
    id: null,
    applicationUserId: null,
    profileType: "",
    minFollowers: 0,
    maxFollowers: 0,
    minFollowings: 0,
    maxFollowings: 0,
    minAvgLikePerPost: 0,
    maxAvgLikePerPost: 0,
    gender: "",
    minAge: 18,
    maxAge: 18,
    locationTargeting: "",
    interestTargeting: "",
    accountTargeting: "",
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    const fetchData = async () => {
      var currentUser = await AuthService.getCurrentUser();

      var userData = await UsersService.getUserInfoById(currentUser.id);

      setValue("id", userData.id);
      setValue("firstName", userData.firstName);
      setValue("lastName", userData.lastName);
      setValue("email", userData.email);
      setValue("instagramUsername", userData.instagramUsername);
      setValue("instagramPassword", userData.instagramPassword);
      setValue("paymentMethod", userData.paymentMethod);
      setValue("subscriptionPlan", userData.subscriptionPlan);
      setValue("isPremium", userData.isPremium);
      setValue("dateCreated", userData.dateCreated);

      var currentTargetSettings = await TargetOptionService.getByUserId(
        currentUser.id
      );
      if (!currentTargetSettings) {
        currentTargetSettings = {
          ...targetSettings,
          applicationUserId: currentUser.id,
        };
      }

      setTargetSettings(currentTargetSettings);

      var followersSlider = document.querySelector("#followers");
      var followingsSlider = document.querySelector("#followings");
      var averageLikesPerPostSlider = document.querySelector(
        "#averageLikesPerPost"
      );
      var ageSlider = document.querySelector("#age");

      if (!followersSlider.noUiSlider) {
        window.noUiSlider.create(followersSlider, {
          start: [
            currentTargetSettings.minFollowers,
            currentTargetSettings.maxFollowers,
          ],
          connect: true,
          tooltips: true,
          step: 500,
          range: {
            min: 0,
            max: 5000,
          },
          format: wNumb({
            decimals: 0,
          }),
        });
        followersSlider.noUiSlider.on("update", function (values, handle) {
          var min = values[0],
            max = values[1];
          setTargetSettings((previousTargetSettings) => ({
            ...previousTargetSettings,
            minFollowers: min,
            maxFollowers: max,
          }));
        });
      }

      if (!followingsSlider.noUiSlider) {
        window.noUiSlider.create(followingsSlider, {
          start: [
            currentTargetSettings.minFollowings,
            currentTargetSettings.maxFollowings,
          ],
          connect: true,
          tooltips: true,
          step: 500,
          range: {
            min: 0,
            max: 5000,
          },
          format: wNumb({
            decimals: 0,
          }),
        });
        followingsSlider.noUiSlider.on("update", function (values, handle) {
          var min = values[0],
            max = values[1];
          setTargetSettings((previousTargetSettings) => ({
            ...previousTargetSettings,
            minFollowings: min,
            maxFollowings: max,
          }));
        });
      }

      if (!averageLikesPerPostSlider.noUiSlider) {
        window.noUiSlider.create(averageLikesPerPostSlider, {
          start: [
            currentTargetSettings.minAvgLikePerPost,
            currentTargetSettings.maxAvgLikePerPost,
          ],
          connect: true,
          tooltips: true,
          step: 100,
          range: {
            min: 0,
            max: 1000,
          },
          format: wNumb({
            decimals: 0,
          }),
        });
        averageLikesPerPostSlider.noUiSlider.on(
          "update",
          function (values, handle) {
            var min = values[0],
              max = values[1];
            setTargetSettings((previousTargetSettings) => ({
              ...previousTargetSettings,
              minAvgLikePerPost: min,
              maxAvgLikePerPost: max,
            }));
          }
        );
      }

      if (!ageSlider.noUiSlider) {
        window.noUiSlider.create(ageSlider, {
          start: [currentTargetSettings.minAge, currentTargetSettings.maxAge],
          connect: true,
          tooltips: true,
          range: {
            min: 18,
            max: 65,
          },
          format: wNumb({
            decimals: 0,
          }),
        });
        ageSlider.noUiSlider.on("update", function (values, handle) {
          var min = values[0],
            max = values[1];
          setTargetSettings((previousTargetSettings) => ({
            ...previousTargetSettings,
            minAge: min,
            maxAge: max,
          }));
        });
      }

      var input1 = document.querySelector("#locationTargetingInput");
      var input2 = document.querySelector("#interestsInput");
      var input3 = document.querySelector("#accountTargetingInput");
      var input4 = document.querySelector("#accountBlacklistInput");

      new window.Tagify(input1);
      new window.Tagify(input2);
      new window.Tagify(input3);
      new window.Tagify(input4);
    };
    fetchData();
    if (window.KTPasswordMeter) {
      window.KTPasswordMeter.createInstances();
    }
  }, []);

  const onProfileTypeChange = (e) => {
    setTargetSettings((previousTargetSettings) => ({
      ...previousTargetSettings,
      profileType: e.target.value,
    }));
  };

  const onGenderChange = (e) => {
    setTargetSettings((previousTargetSettings) => ({
      ...previousTargetSettings,
      gender: e.target.value,
    }));
  };

  const onLocationTargetingChange = (e) => {
    var valuesAsJson = e.target.value;
    if (valuesAsJson !== "") {
      var valuesAsArray = JSON.parse(valuesAsJson);
      var csvLocations = valuesAsArray.map((v) => v.value).join(",");
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        locationTargeting: csvLocations,
      }));
    } else {
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        locationTargeting: null,
      }));
    }
  };

  const onAccountTargetingChange = (e) => {
    var valuesAsJson = e.target.value;
    if (valuesAsJson !== "") {
      var valuesAsArray = JSON.parse(valuesAsJson);
      var csvAccouunts = valuesAsArray.map((v) => v.value).join(",");
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        accountTargeting: csvAccouunts,
      }));
    } else {
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        accountTargeting: null,
      }));
    }
  };

  const onAccountBlacklistChange = (e) => {
    var valuesAsJson = e.target.value;
    if (valuesAsJson !== "") {
      var valuesAsArray = JSON.parse(valuesAsJson);
      var csvAccouunts = valuesAsArray.map((v) => v.value).join(",");
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        accountBlacklist: csvAccouunts,
      }));
    } else {
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        accountBlacklist: null,
      }));
    }
  };

  const onInterestsChange = (e) => {
    var valuesAsJson = e.target.value;
    if (valuesAsJson !== "") {
      var valuesAsArray = JSON.parse(valuesAsJson);
      var csvInterests = valuesAsArray.map((v) => v.value).join(",");
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        interestTargeting: csvInterests,
      }));
    } else {
      setTargetSettings((previousTargetSettings) => ({
        ...previousTargetSettings,
        interestTargeting: null,
      }));
    }
  };

  const onSave = async (e) => {
    e.target.setAttribute("disabled", "disabled");
    e.target.setAttribute("data-kt-indicator", "on");
    if (targetSettings.id) {
      await TargetOptionService.update(targetSettings);
    } else {
      var created = await TargetOptionService.add(targetSettings);
      setTargetSettings(created);
    }
    e.target.removeAttribute("data-kt-indicator");
    e.target.removeAttribute("disabled");
    toast.success(t("SuccessfullyUpdatedTargetSettings"), {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const onSubmit = (formData) => {
    const editUser = async () => {
      await UsersService.editUser(formData);
      toast.success(t("YourProfileSuccessfullyUpdated"), {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    };
    editUser();
  };

  const startCampaign = async () => {
    const result = await TargetOptionService.sendInternalEmails();

    if (result) {
      window.Swal.fire({
        html: t("CampaignStartSuccessfully"),
        icon: "success",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      });
    }
  };

  return (
    <>
      <ToastContainer />
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form"
        noValidate="novalidate"
        action="#"
      >
        <div className="row">
          <div className="col-md-5 mb-20">
            <div
              className="btn-group w-100"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.profileType === "business"
                    ? "active"
                    : ""
                }`}
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="type"
                  value="business"
                  onChange={onProfileTypeChange}
                />
                {t("Business")}
              </label>
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.profileType === "both"
                    ? "active"
                    : ""
                }`}
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="type"
                  value="both"
                  onChange={onProfileTypeChange}
                />
                {t("Both")}
              </label>
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.profileType === "personal"
                    ? "active"
                    : ""
                }`}
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="type"
                  value="personal"
                  onChange={onProfileTypeChange}
                />
                {t("Personal")}
              </label>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5 mb-20">
            <div
              className="btn-group w-100"
              data-kt-buttons="true"
              data-kt-buttons-target="[data-kt-button]"
            >
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.gender === "male"
                    ? "active"
                    : ""
                }`}
                data-kt-button="true"
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="gender"
                  value="male"
                  onChange={onGenderChange}
                />
                {t("Male")}
              </label>
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.gender === "not-selected"
                    ? "active"
                    : ""
                }`}
                data-kt-button="true"
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="gender"
                  value="not-selected"
                  onChange={onGenderChange}
                />
                {t("DoesntMatter")}
              </label>
              <label
                className={`btn btn-outline btn-color-muted btn-active-primary ${
                  targetSettings && targetSettings.gender === "female"
                    ? "active"
                    : ""
                }`}
                data-kt-button="true"
              >
                <input
                  className="btn-check"
                  type="radio"
                  name="gender"
                  value="female"
                  onChange={onGenderChange}
                />
                {t("Female")}
              </label>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 mb-20">
            <label className="form-label mb-15">
              <i className="fa fa-users fs-3"></i>&nbsp;{" "}
              {t("FollowersTheyHave")}
            </label>
            <div id="followers"></div>
            <div className="col mt-2">
              <span className="text-muted">0</span>
              <span className="text-muted float-end">5000+</span>
            </div>
          </div>
          <div className="col-md-2"></div>

          <div className="col-md-5 mb-20">
            <label className="form-label mb-15">
              <i className="fa fa-users fs-3"></i>&nbsp;{" "}
              {t("FollowingsTheyHave")}
            </label>
            <div id="followings"></div>
            <div className="col mt-2">
              <span className="text-muted">0</span>
              <span className="text-muted float-end">5000+</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 mb-20">
            <label className="form-label mb-15">
              <i className="fa fa-heart fs-3"></i>&nbsp;{" "}
              {t("AverageLikesPerPost")}
            </label>
            <div id="averageLikesPerPost"></div>
            <div className="col mt-2">
              <span className="text-muted">0</span>
              <span className="text-muted float-end">1000+</span>
            </div>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5 mb-20">
            <label className="form-label mb-15">
              {" "}
              <i className="fa fa-user fs-3"></i>&nbsp;{t("Age")}
            </label>
            <div id="age"></div>
            <div className="col mt-2">
              <span className="text-muted">18</span>
              <span className="text-muted float-end">65+</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 mb-20">
            <label className="form-label">
              <i className="fa fa-location-dot fs-3"></i>&nbsp;{" "}
              {t("LocationTargeting")}
            </label>
            <input
              className="form-control"
              id="locationTargetingInput"
              placeholder={t("LocationTargetingPlaceholder")}
              value={
                targetSettings && targetSettings.locationTargeting
                  ? targetSettings.locationTargeting
                  : ""
              }
              onChange={onLocationTargetingChange}
            />
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5 mb-20">
            <label className="form-label">
              <i className="fa fa-question fs-3"></i>&nbsp; {t("Interests")}
            </label>
            <input
              className="form-control"
              id="interestsInput"
              placeholder={t("InterestsPlaceholder")}
              value={
                targetSettings && targetSettings.interestTargeting
                  ? targetSettings.interestTargeting
                  : ""
              }
              onChange={onInterestsChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-5 mb-20">
            <label className="form-label">
              <i className="fa fa-user fs-3"></i>&nbsp; {t("AccountTargeting")}
            </label>
            <input
              className="form-control"
              id="accountTargetingInput"
              placeholder={t("AccountTargetingPlaceholder")}
              value={
                targetSettings && targetSettings.accountTargeting
                  ? targetSettings.accountTargeting
                  : ""
              }
              onChange={onAccountTargetingChange}
            />
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-5 mb-20">
            <label className="form-label">
              <i className="fa fa-user-slash fs-3"></i>&nbsp;{" "}
              {t("AccountBlacklist")}
            </label>
            <input
              className="form-control"
              id="accountBlacklistInput"
              placeholder={t("AccountBlacklistPlaceholder")}
              value={
                targetSettings && targetSettings.accountBlacklist
                  ? targetSettings.accountBlacklist
                  : ""
              }
              onChange={onAccountBlacklistChange}
            />
          </div>
        </div>

        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            {t("InstagramUsername")}
          </label>
          <div className="col-lg-8 fv-row">
            <input
              type="text"
              name="address"
              className={`form-control form-control-lg ${
                errors.instagramUsername ? "border-danger" : ""
              }`}
              {...register("instagramUsername", {
                required: t("InstagramUsernameIsRequired"),
              })}
              placeholder={t("InstagramUsername")}
            />
            {errors.instagramUsername && (
              <div className="fv-plugins-message-container invalid-feedback">
                <div data-field="instagramUsername" data-validator="notEmpty">
                  {errors.instagramUsername.message}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-6">
          <label className="col-lg-4 col-form-label fw-semibold fs-6">
            {t("InstagramPassword")}
          </label>
          <div className="col-lg-8 fv-row" data-kt-password-meter="true">
            <div class="position-relative mb-2">
              <input
                type="password"
                placeholder={t("InstagramPassword")}
                name="instagramPassword"
                autoComplete="off"
                className={`form-control form-control-lg ${
                  errors.instagramPassword ? "border-danger" : ""
                }`}
                {...register("instagramPassword", {
                  required: t("InstagramPasswordIsRequired"),
                })}
              />
              <span
                class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2"
                data-kt-password-meter-control="visibility"
              >
                <i class="bi bi-eye-slash fs-2"></i>
                <i class="bi bi-eye fs-2 d-none"></i>
              </span>
            </div>
            {errors.instagramUsername && (
              <div className="fv-plugins-message-container invalid-feedback">
                <div data-field="instagramUsername" data-validator="notEmpty">
                  {errors.instagramUsername.message}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="justify-content-start">
          <button
            type="submit"
            className="btn btn-primary me-10 w-sm-200px"
            onClick={onSave}
          >
            <span className="indicator-label">{t("SaveChanges")}</span>
            <span className="indicator-progress">
              {t("SavingChanges")}{" "}
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
        </div>
      </form>

      <div className="justify-content-start mt-4">
        <button
          type="submit"
          className="btn btn-primary me-10 w-sm-200px"
          data-bs-toggle="modal"
          data-bs-target="#modal_start_campaign"
        >
          <span className="indicator-label">{t("StartCampaign")}</span>
          <span className="indicator-progress">
            {t("StartCampaign")}{" "}
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>

      <div
        className="modal fade"
        id="modal_start_campaign"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <div className="modal-header">
              <img
                src="https://i.imgur.com/n1FbipO.png"
                className="modal-logo"
              />
            </div>
            <div className="modal-body py-lg-15 px-lg-10">
              <IconCards />
              <h3 className="modal-body__title">{t("AlmostThere")}!</h3>
              <p className="modal-body__note">{t("VerifyData")}</p>
              <p className="modal-body__second-note">{t("MadePayment")}</p>
              <div className="modal-body__buttons">
                <button
                  data-bs-toggle="modal"
                  data-bs-target="#modal_payment"
                  // onClick={() => window.open("https://buy.stripe.com/bIY5lm0x0drM7hm4gj", "_blank")}
                >
                  {t("AddPaymentInformation")}
                </button>
                <button data-bs-dismiss="modal" onClick={startCampaign}>
                  {t("PaymentAlreadyMade")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="modal_payment"
        tabIndex="-1"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered mw-500px">
          <div className="modal-content">
            <p className="modal__payment-header">{t("InstagramGrowth")}</p>

            <div className="modal-body py-lg-15 px-lg-5">
              <div class="row g-10 mb-10">
                <div class="col-xl-6">
                  <div class="d-flex h-100 align-items-center">
                    <div class="w-100 d-flex flex-column flex-center payment__startup-package">
                      <div class="text-center">
                        <h1 class="text-black payment-package__header">
                          {t("StartUpPackage")}
                        </h1>
                        <div class="text-black">
                          {t("StartUpPackageDescription")}
                        </div>
                        <div class="text-center">
                          <p
                            class="text-black payment-package__price"
                            data-kt-plan-price-month="149"
                          >
                            149€{" "}
                          </p>
                          <p
                            class="payment-package__period text-black"
                            data-kt-element="period"
                          >
                            {t("PerMonth")}{" "}
                          </p>
                        </div>
                      </div>

                      <a
                        class="btn payment-package__button"
                        href="https://buy.stripe.com/bIY5lm0x0drM7hm4gj"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("SubscribeStartUpPackage")}
                      </a>
                    </div>
                  </div>
                </div>

                <div class="col-xl-6">
                  <div class="d-flex h-100 align-items-center">
                    <div class="w-100 d-flex flex-column flex-center payment__premium-package">
                      <div class="text-center">
                        <h1 class="text-white payment-package__header">
                          {t("PremiumPackage")}
                        </h1>
                        <div class="text-white">
                          {t("PremiumPackageDescription")}
                        </div>
                        <div class="text-center">
                          <p
                            class="text-white payment-package__price"
                            data-kt-plan-price-month="229"
                          >
                            229€{" "}
                          </p>
                          <p
                            class="payment-package__period text-white"
                            data-kt-element="period"
                          >
                            {t("PerMonth")}{" "}
                          </p>
                        </div>
                      </div>

                      <a
                        class="btn payment-package__button"
                        href="https://buy.stripe.com/eVa15693w9bwfNS6oq"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("SubscribePremiumPackage")}
                        <img src="https://i.imgur.com/tgGfXnW.png" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TargetingSettingsView;
