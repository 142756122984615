import http from "../../common/api";
import jwtDecode from "jwt-decode"; // import dependency

class AuthService {
  signIn = (email, password) => {
    try {
      return http
        .post("Auth/login", {
          email,
          password,
        })
        .then((response) => {
          if (response.data.accessToken) {
            const decodedJwt = jwtDecode(response.data.accessToken);
            localStorage.setItem(
              "user",
              JSON.stringify({
                id: decodedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"],
                email: decodedJwt["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
                role: decodedJwt["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"],
                accessToken: response.data.accessToken,
              })
            );
          }
          return true;
        })
        .catch(() => {
          return false;
        });
    } catch {
      return false;
    }
  };

  signUp = (userData) => {
    return http
      .post("Auth/register", {
        firstName: userData.firstName,
        lastName: userData.lastName,
        phone: userData.phone,
        address: userData.address,
        city: userData.city,
        instagramUsername: userData.instagramUsername,
        instagramPassword: userData.instagramPassword,
        subscriptionPlan: "premium",
        paymentMethod: "credit-card",
        email: userData.email,
        password: userData.password,
        confirmPassword: userData.repeatPassword,
      })
      .then((response) => {
        return response.data;
      });
  };

  logout() {
    localStorage.removeItem("user");
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }

  confirmEmail = (userId, emailToken) => {
    return http
      .post(`Auth/confirm-email?userId=${userId}&emailToken=${emailToken}`)
      .then((response) => {
        return response.data;
      });
  };
}

export default new AuthService();
