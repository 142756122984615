import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import AuthService from "../../services/auth/AuthService";
import { useTranslation } from "react-i18next";

const SignInView = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ mode: "onChange" });
  const [showWrongCredentialsMessage, setShowWrongCredentialsMessage] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.backgroundImage = "url('assets/media/auth/bg12.jpg')";
    if (window.KTPasswordMeter) {
      window.KTPasswordMeter.createInstances();
    }
    return () => {
      document.body.style.backgroundImage = "";
    };
  }, []);

  const onSubmit = async (data) => {
    setShowWrongCredentialsMessage(false);
    var result = await AuthService.signIn(data.email, data.password);
    if (result) {
      navigate("/");
    } else {
      setShowWrongCredentialsMessage(true);
    }
  };

  console.log(location)

  useEffect(() => {
   setTimeout(() => {
    navigate(location.pathname, { replace: true });
   }, 4000);
  }, [])

  return (
    <>
      <div className="d-flex flex-column flex-column-fluid flex-lg-row">
        <div className="d-flex flex-center w-lg-50 pt-15 pt-lg-0 px-10">
          <div className="d-flex flex-center flex-lg-start flex-column">
            {/* <a href="../../demo1/dist/index.html" className="mb-7">
              <img alt="Logo" src="assets/media/logos/custom-3.svg" />
            </a>
            <h2 className="text-white fw-normal m-0">{t("Slogan")}</h2> */}
          </div>
        </div>
        <div className="d-flex flex-center w-lg-50 p-10">
          <div className="card rounded-3 w-md-550px">
            <div className="card-body p-10 p-lg-20">
              <form onSubmit={handleSubmit(onSubmit)} className="form w-100" noValidate="novalidate" action="#">
                <div className="text-center mb-11">
                  <h1 className="text-dark fw-bolder mb-3">{t("SignIn")}</h1>
                  <div className="text-gray-500 fw-semibold fs-6">{t("SignInSubtitle")}</div>
                </div>

                <div className="separator separator-content my-14">
                  <span className="w-125px text-gray-500 fw-semibold fs-7">{t("SignIn")}</span>
                </div>
                {showWrongCredentialsMessage && (
                  <div className="alert alert-danger d-flex align-items-center p-5 mb-10">
                    <div className="d-flex flex-column">
                      <span>{t("EmailOrPasswordInvalid")}</span>
                    </div>
                  </div>
                )}
                {location.state && location.state.firstName && location.state.lastName && !showWrongCredentialsMessage &&(
                  <div className="alert alert-success d-flex align-items-center p-5 mb-10">
                    <span className="svg-icon svg-icon-2hx svg-icon-success me-4">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          opacity="0.3"
                          d="M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z"
                          fill="currentColor"
                        ></path>
                        <path
                          d="M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                    <div className="d-flex flex-column">
                      <h4 className="mb-1 text-success">{t("YouHaveSuccessfullySignedUp")}</h4>
                      <span>{t("NowYouCanLogInUsingYourRegisteredMail")}.</span>
                    </div>
                  </div>
                )}

                <div className="fv-row mb-8">
                  <input
                    type="text"
                    placeholder={t("Email")}
                    name="email"
                    autoComplete="off"
                    className={`form-control bg-transparent ${errors.email ? "border-danger" : ""}`}
                    {...register("email", {
                      required: t("EmailIsRequired"),
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: t("EmailInvalidFormat"),
                      },
                    })}
                  />
                  {errors.email && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="email" data-validator="notEmpty">
                        {errors.email.message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="fv-row mb-3" data-kt-password-meter="true">
                  <div class="position-relative mb-3">
                    <input
                      type="password"
                      placeholder={t("Password")}
                      name="password"
                      autoComplete="off"
                      className={`form-control bg-transparent ${errors.password ? "border-danger" : ""}`}
                      {...register("password", {
                        required: t("PasswordIsRequired"),
                      })}
                    />
                    <span class="btn btn-sm btn-icon bg-white position-absolute translate-middle top-50 end-0 me-n2" data-kt-password-meter-control="visibility">
                      <i class="bi bi-eye-slash fs-2"></i>
                      <i class="bi bi-eye fs-2 d-none"></i>
                    </span>
                  </div>
                  {errors.password && (
                    <div className="fv-plugins-message-container invalid-feedback">
                      <div data-field="password" data-validator="notEmpty">
                        {errors.password.message}
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                  <div></div>
                  {/* <a href="#!" className="link-primary">
                    {t("ForgotPassword")}
                  </a> */}
                </div>
                <div className="d-grid mb-10">
                  <button type="submit" className="btn btn-primary" disabled={!isValid}>
                    {t("SignIn")}
                  </button>
                </div>
                <div className="text-gray-500 text-center fw-semibold fs-6">
                  {t("NotMemberYet")}
                  <a href="#!" onClick={() => navigate("/sign-up")} className="link-primary">
                    &nbsp;{t("SignUp")}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignInView;
