import React from "react";
import { Routes, Route } from "react-router-dom";

import { NotFound } from "../navigation/NotFound";
import { ROOT, SIGN_IN, SIGN_UP, TARGETING_SETTINGS, USERS, MY_PROFILE, USER_DASHBOARD, EMBED_IG_POST, EMAIL_CONFIRMATION} from "../navigation/CONSTANTS";
import SignInView from "../pages/sign-in/SignInView";
import SignUpView from "../pages/sign-up/SignUpView";
import ProtectedRoute from "../services/auth/ProtectedRoute";
import { Layout } from "../pages/_layout/Layout";
import TargetingSettingsView from "../pages/targeting-settings/TargetingSettingsView";
import HomeView from "../pages/home/HomeView";
import UsersView from "../pages/users/UsersView";
import MyProfileView from "../pages/my-profile/MyProfileView";
import UserDashboardView from "../pages/users/UserDashboardView";
import EmbedIGPostView from "../pages/embed-ig-post/EmbedIGPostView";
import EmailConfirmView from "../pages/email-confirm/EmailConfirmView";

export const RouterConfig = () => {
  return (
    <Routes>
      <Route exact path={SIGN_IN} element={<SignInView />} />
      <Route exact path={SIGN_UP} element={<SignUpView />} />
      <Route exact path={EMBED_IG_POST} element={<EmbedIGPostView />} />
      <Route exact path={EMAIL_CONFIRMATION} element={<EmailConfirmView />} />

      <Route
        path={ROOT}
        element={
          <ProtectedRoute>
            <Layout></Layout>
          </ProtectedRoute>
        }
      >
        <Route exact path={ROOT} element={<HomeView />} />
        <Route exact path={TARGETING_SETTINGS} element={<TargetingSettingsView />} />
        <Route exact path={USERS} element={<UsersView />} />
        <Route exact path={USER_DASHBOARD} element={<UserDashboardView />} />
        <Route exact path={MY_PROFILE} element={<MyProfileView />} />
      </Route>
      {/* <Route
          path={ROOT}
          element={
            <ProtectedRoute>
              <Layout></Layout>
            </ProtectedRoute>
          }
        >
          <Route exact path={ROOT} element={<Home />} />
          <Route exact path={ORDERS} element={<Orders />} />
          <Route exact path={DOCUMENTS} element={<Documents />} />

          <Route exact path={PROFILE} element={<Profile />} />

        </Route> */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
